/* @flow */

import type { SortableProductList } from "shop-state/types";

import React, { useState, useMemo, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useData, useSendMessage } from "crustate/react";
import { StoreInfoContext } from "entrypoint/shared";
import { useTranslate } from "@awardit/react-use-translate";
import { AllProductsData, CurrentPageInfoData, CustomerData } from "data";
import { allProducts as loadAllProducts } from "@crossroads/shop-state/all-products";
import { OffCanvasFilterMenu, useFilter, ActiveFilters } from "@crossroads/ui-components";
import { Title } from "components/UiComponents";
import { parseParams } from "helpers/location-search-string";
import { useOpenFiltermenu } from "helpers/use-open-filtermenu";
import { constructSortableValues } from "helpers/filters";
import { tryParseJSONObject } from "helpers/utils";
import { getCurrentPageInfo } from "state/current-page-info";
import { PAGE_SIZE } from "effects/route";
import Wrapper from "components/Wrapper";
import ProductList from "components/ProductList";
import ProductCarousel from "components/ProductCarousel";
import Filterbar from "components/Filterbar";
import Breadcrumbs from "components/Breadcrumbs";
import PopularCategoryCarousel from "components/PopularCategoryCarousel";
import CurrentPageInfo from "components/CurrentPageInfo";
import PaginatedProductList from "components/CategoryView/paginated-product-list";

import styles from "./styles.scss";
import cn from "classnames";

type Props = {
  allProductsData: SortableProductList,
  updating: boolean,
  title: string,
  className?: string,
};

type WrapperProps = {
  className?: string,
};

const AllProductsViewWrapper = ({ className = "" }: WrapperProps) => {
  const all = useData(AllProductsData);
  const {
    content: {
      allproductsview: { pageTitle },
    },
  } = useContext(StoreInfoContext);

  if (all.state === "LOADED" || all.state === "UPDATING") {
    return (
      <AllProductsView
        title={pageTitle}
        updating={all.state === "UPDATING"}
        allProductsData={all.data}
        className={className}
      />
    );
  }

  return <HintAllProductsView title={pageTitle} />;
};

const AllProductsView = ({ allProductsData, updating, title, className }: Props) => {
  const t = useTranslate();
  const location = useLocation();
  const customerData = useData(CustomerData);
  const sendMessage = useSendMessage();
  const params = useMemo(() => parseParams(location.search), [location.search]);
  const numPages = Math.ceil(allProductsData.totalCount / PAGE_SIZE);
  const openFiltermenu = useOpenFiltermenu();
  const currentPageInfoData = useData(CurrentPageInfoData);
  const customer = customerData.state === "LOGGED_IN" ? customerData.data : null;
  const {
    routes: {
      allProductsView,
    },
    content: {
      allproductsview: {
        popularCategories,
        popularCategoriesTitle,
        autoFilterFromPoints,
        autoFilterMinPoints,
      },
      productCarousel: { useOnAllProductsView },
    },
  } = useContext(StoreInfoContext);

  const popularCategoriesJson = popularCategories !== undefined &&
    tryParseJSONObject(popularCategories) ? JSON.parse(popularCategories) : "";
  const gotCurrentPageInfo = (currentPageInfoData.data?.allproductsview?.length || 0) > 0;

  const nonLinearSlider = true;

  useEffect(() => {
    sendMessage(getCurrentPageInfo("ALLPRODUCTSVIEW"));
  }, []);

  const [page, setPage] = useState(() =>
    Math.max(1, parseInt(params.page, 10) || 1)
  );

  const userPoints = (customer) ? customer.awardit.activePoints : null;
  const isAutoFilterMinPointsActive =
        autoFilterFromPoints &&
        autoFilterFromPoints === true &&
        autoFilterMinPoints !== null && userPoints !== null &&
        Number.parseInt(autoFilterMinPoints, 10) < userPoints ?
        customer?.awardit.activePoints : 0;

  const useFilterStateConfig = {
    loading: updating,
    productList: allProductsData,
    usePoints: true,
    incVat: false,
    load: location => {
      sendMessage(loadAllProducts(location, false));
    },
    autoFilterMinPoints: isAutoFilterMinPointsActive,
  };

  const filterState = useFilter(useFilterStateConfig);

  // Update page when URL is updated
  useEffect(() => {
    setPage(parseInt(params.page, 10) || 1);
  }, [params]);

  return (
    <div className={styles.block}>
      <Helmet title={title} />

      {gotCurrentPageInfo &&
        <CurrentPageInfo className={styles.currentPageInfo} />
      }

      <Wrapper variant="pageWrapper" className={cn("awardit-allProductsViewWrapper", "awardit-pageWrapper", styles.wrapper)}>

        <Breadcrumbs className="awardit-allProductsViewBreadCrumbs" current={title} />

        {(popularCategoriesJson && page === 1) &&
          <div className={styles.popularCategoryCarousel}>
            <PopularCategoryCarousel
              popularCategories={popularCategoriesJson}
              title={popularCategoriesTitle}
            />
          </div>
        }
        {page === 1 && useOnAllProductsView &&
          <div className={cn(styles.popularProducts, className)}>
            <ProductCarousel />
          </div>
        }

        <div className={cn(styles.header, "awardit-allProductsViewHeader")}>
          <Title>{title}</Title>
          <p className={styles.total_count}>{t("FILTER.ACTIVE_FILTERS.TOTAL_COUNT", { count: filterState.totalCount })}</p>
        </div>

        <div className={styles.activeFiltersRow}>
          {filterState.active.filters.length > 0 &&
            <h2 className={styles.activeFiltersRowHeader}>
              {t("FILTER.ACTIVE_FILTERS.COUNT")}
              <span className={styles.activeFiltersRowCount}>{` (${filterState.active.filters.length})`}</span>
            </h2>
          }
          <ActiveFilters
            hideEmptyFilters
            filterState={filterState}
            className={cn(styles.activeFilters, "awardit-activeFilters")}
          />
        </div>

        <Filterbar
          openFilters={openFiltermenu.openFiltermenu}
          content={{ autoFilterFromPoints, autoFilterMinPoints }}
          route={allProductsView}
          user={customer}
          className={styles.filterbar}
          filterState={{
            ...filterState,
            sort: {
              ...filterState.sort,
              values: Array.isArray(filterState.sort.values) ?
                constructSortableValues(filterState.sort.values, t) :
                [],
            },
          }} />

        <PaginatedProductList
          updating={updating}
          page={page}
          numPages={numPages}
          perPage={PAGE_SIZE}
          productList={allProductsData}
          className="awardit-allProductsViewProductList"
          category={title}
        />

        <OffCanvasFilterMenu
          isOpen={openFiltermenu.isOpen}
          close={openFiltermenu.closeFiltermenu}
          filterState={filterState}
          nonLinearSlider={nonLinearSlider}
          exponentiation={4}
          containerClass="awardit-OffCanvasFilterMenu"
        />
      </Wrapper>
    </div>
  );
};

export const HintAllProductsView = ({ title }: { title: string }) => {
  return (
    <div className={styles.block}>
      <Wrapper variant="pageWrapper" className={cn("awardit-pageWrapper", "awardit-allProductsViewWrapper", styles.wrapper)}>
        <Helmet title={title} />

        <Breadcrumbs current={title} />

        <h1 className={styles.header}>
          {title}
        </h1>
        <p className={styles.total_count} />

        <ProductList products={[null, null, null, null, null, null, null, null]} />
      </Wrapper>
    </div>
  );
};

export default AllProductsViewWrapper;
