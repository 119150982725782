/* @flow */

import React, { useEffect, useState, useContext, useCallback } from "react";
import { useData, useSendMessage } from "crustate/react";
import { addMessage } from "state/messages";
import { useTranslate } from "@awardit/react-use-translate";
import { useClient, StoreInfoContext } from "entrypoint/shared";
import { postReportForm } from "queries";
import { getReportsform } from "state/reports";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Breadcrumbs from "components/Breadcrumbs";
import Wrapper from "components/Wrapper";
import { Input, Dropdown, DropdownItem, Button } from "@crossroads/ui-components";
import { Title } from "components/UiComponents";
import { Form } from "@awardit/formaggio";
import Field from "components/Field";
import ReportRow from "./ReportRow";
import CloseIcon from "icons/close-mini.svg";
import cn from "classnames";
import { ReportFormData, ReportListData } from "data";

type formProduct = {
  prodname: string,
  prodid: string,
  prodnum?: number,
};

import styles from "./styles.scss";

const ReportsView = () => {
  const reportData = useData(ReportFormData);
  const reportlistData = useData(ReportListData);
  const [reportFormId, setReportFormId] = useState(reportData.state === "LOADED" ? reportData.data.id : "");
  const t = useTranslate();
  const [state, setState] = useState<any>({});
  const [dropdownValue, setDropdownValue] = useState<string>("");
  const [currentProd, setCurrentProd] = useState<formProduct>({ prodname: "", prodid: "", prodnum: 1 });
  const [reports, setReports] = useState<any>([]);
  const client = useClient();
  const sendMessage = useSendMessage();
  const { routes, content: { reportsview } } = useContext(StoreInfoContext);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    const date = new Date();
    const currentMonth = date.getMonth() + 1 < 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
    const currentDate = date.getDate() < 9 ? "0" + date.getDate() : date.getDate();
    const currentDateString = `${date.getFullYear()}-${currentMonth}-${currentDate}`;

    setState({ ...state,
      date: currentDateString,
    });
  }, []);

  const load = useCallback(() => {
    sendMessage(getReportsform({ district: reportFormId }));
  }, [sendMessage, getReportsform, reportData, reportFormId]);

  useEffect(() => {
    load();
  }, [reportFormId]);

  const submitReports = async (e, value) => {
    e.preventDefault();

    if (reportData.state === "LOADED") {
      const reportFormData: any = {
        id: reportFormId ? reportFormId : reportData.data.id,
        purchaseid: value.purchaseid,
        datum: value.date,
      };
      const valueKeys = Object.keys(value);
      const rows = [];
      const orderedReports = [];

      valueKeys.forEach(elem => {
        if (elem.includes("row")) {
          const valArray = elem.split("-");
          const matchingRow = rows.find(row => row.productid === valArray[2]);
          const rowId = valArray.splice(2).join("-");

          if (matchingRow === undefined) {
            const tempRow = {
              productid: rowId,
              filled: 0,
            };

            if (typeof (value[elem]) === "boolean" && value[elem] === true) {
              tempRow[valArray[1]] = "#";
            }
            else {
              tempRow[valArray[1]] = value[elem];
            }

            if (reportData.data.rows?.length) {
              // Set filled since required in
              // Java because of weird form handling
              tempRow.filled =
              reportData.data.rows.find(row => row.productid === rowId)?.reqflag ? 1 : 0;
            }

            rows.push(tempRow);
          }

          if (matchingRow !== undefined) {
            if (typeof (value[elem]) === "boolean" && value[elem] === true) {
              matchingRow[valArray[1]] = value[elem] === true ? "#" : null;
            }
            else {
              matchingRow[valArray[1]] = value[elem];
            }
          }
        }
      });

      if (reports.length > 0) {
        reports.forEach(item => {
          const newItem = {
            prodnum: item.prodnum,
            prodid: item.prodid,
          };
          orderedReports.push(newItem);
        });
      }

      if (rows.length > 0) {
        reportFormData.rows = rows;
      }

      if (orderedReports.length > 0) {
        reportFormData.prodtable = orderedReports;
      }

      setIsSubmitting(true);

      const response = await client(postReportForm, { form: (reportFormData: any) });

      if (response.postReportForm.result.includes("ERROR")) {
        sendMessage(addMessage("SEND_ORDER_ERROR", "error"));
        setIsSubmitting(false);
      }
      else {
        sendMessage(addMessage("SEND_ORDER_SUCCESS", "success"));
        setIsSubmitting(false);
        setReports([]);
        setState([]);
      }
    }
  };

  const removeProd = prod => {
    const i = reports.indexOf(prod);

    if (i > -1) {
      setReports(reports.filter(item => item !== prod));
    }
  };

  return (
    <Wrapper variant="pageWrapper" className={cn("awardit-pageWrapper", styles.wrapper)}>
      <Helmet
        title={reportsview.pageTitle}
      />

      <Breadcrumbs current={reportsview.pageTitle} />

      <div className={styles.intro}>
        {reportsview.pageTitle && <Title>{reportsview.pageTitle}</Title>}
        {reportsview.description && <p>{reportsview.description}</p>}

        {/* eslint-disable react/no-danger */}
        {reportsview.descriptionExtraHtml &&
        <div
          className={styles.htmlBlock}
          dangerouslySetInnerHTML={{ __html: reportsview.descriptionExtraHtml }}
        />}
        {/* eslint-enable react/no-danger */}
        {routes.reportsHistory &&
        routes.reportsHistory.toggle &&
        typeof routes.reportsHistory.url === "string" &&
        routes.reportsHistory.url.length > 0 &&
        <Link
          className={styles.link}
          to={routes.reportsHistory.url}
        >
          {t("REPORTS.HISTORY_BUTTON")}
        </Link>
        }

        {routes.reportApproval &&
        routes.reportApproval.toggle &&
        typeof routes.reportApproval.url === "string" &&
        routes.reportApproval.url.length > 0 &&
        reportData.state === "LOADED" &&
        reportData.data.canverify &&
        <Link
          className={styles.link}
          to={routes.reportApproval.url}
        >
          {reportData.data.canverify.name}

        </Link>
        }
      </div>

      {reportlistData.state === "LOADED" &&
      reportlistData.data !== null &&
      reportlistData.data.length > 1 &&
        <Dropdown
          className={styles.districtSelector}
          name="productName"
          variant="primary"
          value={reportFormId !== "" ? reportFormId : (reportlistData.data[0].id).toString()}
          onChange={(value: any) => {
            setReportFormId(value);
          }}
        >
          {reportlistData.data.map(item => (
            <DropdownItem
              key={`from_${item.id}`}
              className={styles.inputWrapper}
              value={`${item.id}`}
            >
              {`${item.name}`}
            </DropdownItem>
          ))}
        </Dropdown>
      }

      {reportlistData.state === "LOADED" &&
      reportlistData.data !== null &&
      reportlistData.data.length === 0 &&
      <p>{t("REPORTS.EMPTY")}</p>
      }

      <div className={styles.contentWrapper}>

        <div className={styles.formWrapper}>
          {reportData.state === "LOADED" &&
            <Form
              value={(state: any)}
              onSubmit={submitReports}
              onChange={x => setState({ ...state, ...(x: any) })}
            >

              <div className={styles.reportWrapper}>
                <h2>{reportData.data.name}</h2>

                <section className={styles.formTop}>

                  {reportData.data.filldate === 2 && (
                    <Field
                      type="date"
                      value={reportData.data.datum}
                      label={reportData.data.datename ? reportData.data.datename : "Datum"} name="date" />
                  )}

                  {reportData.data.fillpurcid === 1 && (
                    <Field
                      required
                      type="text"
                      name="purchaseid"
                      label={reportData.data.purcidname}
                    />
                  )}
                </section>

                {reportData.state === "LOADED" && reportData.data.rows !== null && reportData.data.rows.length > 0 && (
                  reportData.data.rows.map(x => (
                    <ReportRow
                      {...x}
                      key={`reportRow-${x.productid}`}
                      setState={(value, str) => setState({ ...state, [str]: value })}
                    />
                  ))
                )}
                {reportData.data.products !== null && (
                  <section className={styles.formBottom}>
                    <div className={styles.DropdownWrapper}>
                      <label>{reportsview.productInputLabel}</label>
                      <Dropdown
                        className={styles.selector}
                        name="productName"
                        variant="primary"
                        value={dropdownValue}
                        placeholder={reportsview.productInputPlaceholder}
                        onChange={(value: any) => {
                          setDropdownValue(value);
                          setCurrentProd({
                            ...currentProd,
                            prodname: value.split("---")[0],
                            prodid: value.split("---")[1],
                          });
                        }}
                      >
                        {reportData.data.products.map(product => (
                          <DropdownItem
                            key={`from_${product.productid}`}
                            className={styles.inputWrapper}
                            value={`${product.productname}---${product.productid}`}
                          >
                            {`${product.productname}`}
                          </DropdownItem>
                        ))}
                      </Dropdown>
                    </div>

                    <div className={styles.countWrapper}>
                      <Input
                        label={reportsview.amountInputLabel}
                        type="number"
                        name="prodnum"
                        value={currentProd.prodnum}
                        onChange={e => setCurrentProd({ ...currentProd,
                          prodnum: typeof parseInt(e.target.value, 10) === "number" ? parseInt(e.target.value, 10) : 1 })
                        }
                      />
                      <Button
                        type="button"
                        onClick={() => {
                          if (!reports.includes(currentProd)) {
                            setReports([...reports, currentProd]);
                          }
                        }}
                      >
                        {reportsview.addInputLabel}
                      </Button>
                    </div>
                  </section>
                )}
              </div>

              <div className={styles.orderWrapper}>
                {reports.map((item, i) => (
                  <div key={i} className={styles.orderItem}>
                    {item.prodname} {item.prodnum}
                    <CloseIcon className={styles.closeIcon} onClick={() => removeProd(item)} />
                  </div>
                ))}
                <Button disabled={(Boolean(reportData.data.products !== null && reports.length === 0))} loading={isSubmitting} type="submit">{reportsview.submitInputLabel}</Button>
              </div>
            </Form>
          }
        </div>
      </div>
    </Wrapper>
  );
};

export default ReportsView;
