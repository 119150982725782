/* @flow */

import type { RegFormConfigField, DistrictItem } from "shop-state/types";
import type { QueryObject, QueryValue } from "components/RegisterView";

import React, { useState, useContext, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslate } from "@awardit/react-use-translate";
import { useClient, StoreInfoContext } from "entrypoint/shared";
import { getRegisterFormStyling } from "queries";
import { Form, isRequired, isEmail, isPhone, isNumeric, rules, match } from "@awardit/formaggio";
import Field, { TogglePasswordField, CheckboxField } from "components/Field";
import { Dropdown, DropdownItem, SearchableDropdown } from "@crossroads/ui-components";
import Button from "components/Button";
import { getTranslationFromKey, mapKeysToQueryParams } from "helpers/forms";

import cn from "classnames";
import styles from "./styles.scss";

type Props = {
  formArr: Array<{...RegFormConfigField, key: string }>,
  value: any,
  setValue: any,
  postForm: any,
  partnerId: number,
  queryParams: QueryObject,
  genderValue: ?string,
  setGenderValue: ?(string) => void,
  districtList: ?Array<DistrictItem>,
};

type FormDataValue = boolean | string | number | FormData;
type FormData = { [key: string]: FormDataValue };

type ValidationError = {
  error: string,
  field: string,
};

const GENDERS = ["Female", "Male", "Unspecified"];
// const pnrRegex = /^(19|20)(\d{2})(0[1-9]|1[0-2])([0-3]\d{1})(-|\s?)(\d{4})$/;

const passwordValidate = (f: string) => (t: FormData) =>
  typeof t[f] === "string" && t[f].length > 5 ? [] : [{
    error: "PASSWORD_VALIDATE",
    field: f,
    lengthGt: 5,
  }];

const matchRegex = (f: string, p: RegExp, msg?: string) => (t: FormData): Array<ValidationError> =>
  typeof t[f] === "string" && p.test(t[f]) ? [] : [{ error: msg ?? "MATCH_PATTERN", field: f }];

const createStyleObjFromArr = (arr: Array<{name: string, value: string}>) => {
  const obj = {};
  arr.forEach(item => {
    const key = item.name.replace("global--", "");
    obj[key] = `#${item.value}`;
  });

  return obj;
};

const RegisterForm = ({
  formArr,
  value,
  setValue,
  postForm,
  partnerId,
  queryParams,
  genderValue,
  setGenderValue,
  districtList,
}: Props) => {
  const t = useTranslate();
  const client = useClient();
  const validationRules = [];
  const [submitting, setSubmitting] = useState(false);
  const [customFormStyling, setCustomFormStyling] = useState({});
  const [displayErrors, setDisplayErrors] = useState(false);
  const {
    content: { registerform },
    info, info: { countries, locale },
    routes: { termsView },
  } = useContext(StoreInfoContext);

  const genders = useMemo(() => GENDERS.map(x => ({
    code: x,
    name: t(`ACCOUNT.GENDER.${x.toUpperCase()}`),
  })), [t]);

  const sortedCountries = useMemo(() => {
    return countries.slice().sort((a, b) => {
      return a.name.localeCompare(
        b.name,
        locale.split("_")[0],
        { sensitivity: "base" }
      );
    });
  }, [countries, locale]);

  const setTitle = (field: {...RegFormConfigField, key: string }, altKey?: string) => {
    const title = (altKey === null || altKey === undefined) ?
      t(getTranslationFromKey(field.key)) :
      t(getTranslationFromKey(altKey));
    return `<p>${field.name ?? title} ${field.req === "required" ? "*" : ""}</p>`;
  };

  const _setGender = (value: ?(string | number | QueryValue)) => {
    if (setGenderValue && (value === "Female" || value === "Male" || value === "Unspecified")) {
      setGenderValue(value);
    }

    if (setGenderValue && (value === "165" || value === "166" || value === "167")) {
      switch (value) {
        case "165": setGenderValue("Male");
          break;
        case "166": setGenderValue("Female");
          break;
        case "167": setGenderValue("Unspecified");
          break;
        default: break;
      }
    }
  };

  const getTermsLink = () => {
    if (termsView?.toggle && termsView.url && termsView.title) {
      if (info.code === "beijer_sek" && (partnerId === 5451 || partnerId === 5450)) {
        return { title: termsView.title, url: `${termsView.url}/${partnerId}` };
      }

      return { title: termsView.title, url: termsView.url };
    }

    return null;
  };

  const termsLink = getTermsLink();
  const termsInfo = termsLink !== null ? t("REGISTER.TERMS", { link: termsLink.url }) : null;

  formArr.forEach(item => {
    if (item.req === "required" && item.show !== false) {
      validationRules.push(isRequired(`key-${item.key}`));
    }

    if (item.key === "email") {
      validationRules.push(isEmail(`key-${item.key}`));
    }

    if (item.key === "cell") {
      if (item.pattern === null || item.pattern.length <= 0) {
        validationRules.push(isPhone(`key-${item.key}`));
      }
      else {
        validationRules.push(
          matchRegex(`key-${item.key}`, new RegExp(item.pattern), "PHONE")
        );
      }
    }

    if (item.pattern !== null && item.pattern.length > 0 && item.key !== "cell") {
      validationRules.push(matchRegex(`key-${item.key}`, new RegExp(item.pattern)));
    }

    if (item.digits === true) {
      validationRules.push(isNumeric(`key-${item.key}`));
    }
  });

  if (registerform.customTermsToggle === true && info.partnerId === partnerId) {
    validationRules.push(isRequired("key-acceptCustom"));
  }

  const validateMyForm = rules([
    passwordValidate("key-passw"),
    match("key-passw", "key-passwCheck"),
    ...validationRules,
  ]);

  const formErrors = validateMyForm((value: any));

  const hasErrors = () => {
    setDisplayErrors(true);
  };

  const submit = async e => {
    e.preventDefault();
    setSubmitting(true);
    await postForm();
    setTimeout(() => {
      setSubmitting(false);
    }, 1000);
  };

  useEffect(() => {
    // handles custom query params
    const obj = {};
    formArr.forEach(field => {
      if (field.key === "birth") {
        if (queryParams.birthyear || queryParams.birthmonth || queryParams.birthday) {
          const { birthyear, birthmonth, birthday } = queryParams;
          const year = birthyear && typeof birthyear === "string" ? birthyear : "1980";
          const month = birthmonth && typeof birthmonth === "string" ? birthmonth : "01";
          const day = birthday && typeof birthday === "string" ? birthday : "01";
          obj[`key-${field.key}`] = `${year}-${month}-${day}`;
        }
      }

      if (queryParams[mapKeysToQueryParams(field.key)]) {
        if (field.key === "gender") {
          _setGender(queryParams[mapKeysToQueryParams(field.key)]);
          return;
        }

        if (field.key === "reg3" && (districtList && districtList.length > 0)) {
          districtList.forEach(district => {
            if (district.salesgroupid === queryParams[mapKeysToQueryParams(field.key)]) {
              obj[`key-${field.key}`] = district.salesgroupid;
            }
          });
          return;
        }

        if (field.key === "retailer" && field.options && field.options.length > 0) {
          const { options } = field;

          options.forEach(option => {
            if (option.id === queryParams[mapKeysToQueryParams(field.key)]) {
              obj[`key-${field.key}`] = option.id;
            }
          });
          return;
        }

        obj[`key-${field.key}`] = queryParams[mapKeysToQueryParams(field.key)];
      }
    });
    setValue(obj);
  }, []);

  useEffect(() => {
    const getFormStyling = async () => {
      const response = await client(getRegisterFormStyling, { partner: partnerId });
      if (!response.getRegisterFormStyling) {
        return;
      }

      setCustomFormStyling(await createStyleObjFromArr(response.getRegisterFormStyling));
    };

    getFormStyling();
  }, [client]);

  return (
    <div className={cn(styles.registerFormWrapper, "awardit-registerFormWrapper")}>
      <div className={styles.registerFormOuter}>
        {info.code === "beijer_sek" && (partnerId === 5451 || partnerId === 5450) && (
          <div className={styles.formLogoWrapper}>
            {info.code === "beijer_sek" && partnerId === 5451 && <img src="/uploads/5451_centro_centroblue_text_only.png" alt="centro" /> }
            {info.code === "beijer_sek" && partnerId === 5450 && <img src="/uploads/5450_Kakeldaxgruppen_LOGO_SVART.png" alt="kakeldaxgruppen" /> }
          </div>
        )}
        <div className={styles.formHeader}>
          <span className={styles.heading}>{registerform.heading}</span>
          <Link
            to="/login"
            className={styles.loginLink}
            style={{ color: customFormStyling.colorPrimary }}
          >
            {t("REGISTER.EXISITING_ACCOUNT")}
          </Link>
        </div>

        <Form
          className={styles.registerForm}
          errors={formErrors}
          value={value}
          onError={hasErrors}
          onSubmit={submit}
          onChange={x => setValue({ ...value, ...(x: any) })}
        >
          {/* eslint-disable react/no-danger */}
          {formArr.map((field, i) => {
            switch (field.key) {
              default:
                const generalTitle = setTitle(field);
                if (field.show === false) {
                  return null;
                }

                const inputFormat = x => {
                  if (x.key === "birth") {
                    return "date";
                  }

                  return "text";
                };

                return (
                  <Field
                    key={`${field.key}-${i}`}
                    label={<div dangerouslySetInnerHTML={{ __html: generalTitle }} />}
                    name={`key-${field.key}`}
                    wrapperClassName={cn(styles.textField)}
                    maxLength={field.max !== 0 ? field.max : null}
                    type={inputFormat(field)}
                    pattern={
                      (field.pattern !== null && field.pattern.length > 0) ? field.pattern : null
                    }
                  />
                );
              case "passw":
                const passTitle = setTitle(field);
                const passTitleTwo = setTitle(field, "passwCheck");
                return (
                  <div key={`${field.key}-${i}`} className={styles.passWrapper}>
                    <TogglePasswordField
                      noColorBtn
                      name={`key-${field.key}`}
                      autoComplete="off"
                      label={<div dangerouslySetInnerHTML={{ __html: passTitle }} />}
                      wrapperClassName={cn(styles.textField, styles.passField)}
                      maxLength={field.max !== 0 ? field.max : null}
                      type="password"
                    />
                    <TogglePasswordField
                      noColorBtn
                      name={`key-${field.key}Check`}
                      autoComplete="off"
                      label={<div dangerouslySetInnerHTML={{ __html: passTitleTwo }} />}
                      wrapperClassName={cn(styles.textField, styles.passField)}
                      maxLength={field.max !== 0 ? field.max : null}
                      type="password"
                    />
                  </div>
                );
              case "country":
                const countryTitle = setTitle(field);
                if (field.show === false) {
                  return null;
                }

                return (
                  <div key={`${field.key}-${i}`} className={styles.dropdownField}>
                    <div
                      className={styles.dropdownText}
                      dangerouslySetInnerHTML={{ __html: countryTitle }}
                    />
                    <SearchableDropdown
                      className={cn(styles.dropdown,
                        { [styles.dropdown__maxHeight]: sortedCountries.length > 5 }
                      )}
                      placeholder={t("REGISTER.COUNTRY")}
                      value={value[`key-${field.key}`]}
                      onChange={e => setValue({ ...value, [`key-${field.key}`]: e })}
                    >
                      {sortedCountries.map((option, i) => (
                        <DropdownItem key={i} value={option.code}>{option.name}</DropdownItem>
                      ))}
                    </SearchableDropdown>
                    {formErrors.map(error => {
                      if (error.field === `key-${field.key}` && displayErrors === true) {
                        return <span className={styles.error}>{t("VALIDATION.REQUIRED")}</span>;
                      }

                      return null;
                    })}
                  </div>
                );
              case "gender":
                const genderTitle = setTitle(field);
                if (field.show === false) {
                  return null;
                }

                return (
                  <div key={`${field.key}-${i}`} className={styles.dropdownField}>
                    <div
                      className={styles.dropdownText}
                      dangerouslySetInnerHTML={{ __html: genderTitle }}
                    />
                    <Dropdown
                      className={cn(styles.dropdown,
                        { [styles.dropdown__maxHeight]: genders.length > 5 }
                      )}
                      placeholder={genders[genders.length - 1].name}
                      value={genderValue}
                      onChange={_setGender}
                    >
                      {genders.map((option, i) => (
                        <DropdownItem key={i} value={option.code}>{option.name}</DropdownItem>
                      ))}
                    </Dropdown>
                    {formErrors.map(error => {
                      if (error.field === `key-${field.key}` && displayErrors === true) {
                        return <span className={styles.error}>{t("VALIDATION.REQUIRED")}</span>;
                      }

                      return null;
                    })}
                  </div>
                );
              case "reg3":
                const districtTitle = setTitle(field);
                if (field.show === false || districtList === null || districtList === undefined) {
                  return null;
                }

                return (
                  <div key={`${field.key}-${i}`} className={styles.dropdownField}>
                    <div
                      className={styles.dropdownText}
                      dangerouslySetInnerHTML={{ __html: districtTitle }}
                    />
                    <Dropdown
                      className={cn(styles.dropdown,
                        { [styles.dropdown__maxHeight]: districtList.length > 5 }
                      )}
                      placeholder={t("REGISTER.DISTRICT")}
                      value={value[`key-${field.key}`]}
                      onChange={e => setValue({ ...value, [`key-${field.key}`]: e })}
                    >
                      {districtList.map((option, i) => (
                        <DropdownItem key={i} value={option.salesgroupid}>
                          {option.salesgroupid}
                        </DropdownItem>
                      ))}
                    </Dropdown>
                    {formErrors.map(error => {
                      if (error.field === `key-${field.key}` && displayErrors === true) {
                        return <span className={styles.error}>{t("VALIDATION.REQUIRED")}</span>;
                      }

                      return null;
                    })}
                  </div>
                );
              case "retailer":
                const retailerTitle = setTitle(field);

                const { options } = field;
                if (field.show === false || !options || options.length <= 0) {
                  return null;
                }

                return (
                  <div key={`${field.key}-${i}`} className={styles.dropdownField}>
                    <div
                      className={styles.dropdownText}
                      dangerouslySetInnerHTML={{ __html: retailerTitle }}
                    />
                    <Dropdown
                      className={cn(styles.dropdown,
                        { [styles.dropdown__maxHeight]: options.length > 5 }
                      )}
                      placeholder={t("REGISTER.SHOP")}
                      value={value[`key-${field.key}`]}
                      onChange={e => setValue({ ...value, [`key-${field.key}`]: e })}
                    >
                      {options.map((option, i) => (
                        <DropdownItem key={i} value={option.id}>
                          {option.name}
                        </DropdownItem>
                      ))}
                    </Dropdown>
                    {formErrors.map(error => {
                      if (error.field === `key-${field.key}` && displayErrors === true) {
                        return <span className={styles.error}>{t("VALIDATION.REQUIRED")}</span>;
                      }

                      return null;
                    })}
                  </div>
                );
            }
          })}
          {(registerform.customTermsToggle === true && info.partnerId === partnerId) && (
            <div className={styles.checkboxField}>
              <CheckboxField name="key-acceptCustom">
                <div dangerouslySetInnerHTML={{ __html: registerform.customTermsText }} />
              </CheckboxField>
            </div>
          )}
          {(termsLink !== null) && (
            <div className={styles.checkboxField}>
              <CheckboxField required name="key-accept">
                <div dangerouslySetInnerHTML={{ __html: termsInfo }} />
              </CheckboxField>
            </div>
          )}
          <div className={styles.btnContainer}>
            <Button
              style={{
                backgroundColor: customFormStyling.colorPrimary,
                color: customFormStyling.colorInvertedText,
              }}
              className={styles.submitBtn}
              type="submit"
              disabled={submitting}
              variant="primary"
            >
              {registerform.submit ?? t("COMMON.REGISTER")}
            </Button>
          </div>
          {/* eslint-enable react/no-danger */}
        </Form>
      </div>
    </div>
  );
};

export default RegisterForm;
