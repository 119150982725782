/* @flow */

import React, { useContext } from "react";
import { useData, useSendMessage } from "crustate/react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";
import { TransactionsData, CustomerData } from "data";
import Wrapper from "components/Wrapper";
import Button from "components/Button";
import cn from "classnames";
import { HeadlessLastOrders } from "components/AccountView/last-orders";
import { setMode, MODE } from "state/view-mode";

import styles from "./styles.scss";

type Props = {
  className: string,
};

const AppFooter = ({ className = "" }: Props) => {
  const t = useTranslate();
  const location = useLocation();
  const sendMessage = useSendMessage();
  const {
    content: { appfooter, appfootertransactions },
    categories,
    routes,
    configuration: { showCookieConsent },
  } = useContext(StoreInfoContext);
  const transaction = useData(TransactionsData);
  const customer = useData(CustomerData);
  const memberTargetList = customer.state === "LOGGED_IN" &&
  customer.data &&
  customer.data.memberTargetList &&
  customer.data.memberTargetList.list.length > 0 ?
    customer.data.memberTargetList.list :
    null;
  const {
    footerText,
    copyrightText,
    leftColumnHeading,
    leftColumnText,
    leftColumnButtonText,
    leftColumnButtonLink,
    rightColumnHeading,
    rightColumnText,
    rightColumnButtonText,
    rightColumnButtonLink,
    transactionsHeading,
    transactionsText,
    disableDividers,
  } = appfooter;
  const consentActivated = (showCookieConsent !== null || showCookieConsent !== undefined) &&
    showCookieConsent === true;

  const termsLink = routes.termsView?.toggle && routes.termsView.url && routes.termsView.title ?
    {
      title: routes.termsView.title,
      url: routes.termsView.url,
    } : null;

  const displayTransactions = () => {
    let res = false;

    if (appfootertransactions.home && (location.pathname === "/" || location.pathname.includes("index.html"))) {
      res = true;
      return res;
    }

    if (appfootertransactions.other) {
      res = true;
    }

    for (const [key, value] of Object.entries(appfootertransactions)) {
      if (location.pathname.split("/")[1] === key) {
        res = value;
        return res;
      }
    }

    return res;
  };

  return (
    <footer className={cn(styles.block, className, "awardit-appFooter")}>
      <div className={styles.large}>
        {displayTransactions() && transaction.state === "LOADED" && transaction.data.length > 0 ? (
          <>
            <Wrapper>
              <div className={cn("awardit-appFooterTransaction", styles.transaction)}>
                <div className={cn("awardit-appFooterTransactionLeft", styles.left)}>
                  {transactionsHeading && (
                    <>
                      <h2 className={styles.title}>{transactionsHeading}</h2>
                      <p className={styles.description}>
                        {transactionsText}
                      </p>
                      <Button className="awardit-appFooterTransactionLeftButton" to={routes.accountView.url} variant="primary">
                        {t("FOOTER.LINKS.GO_TO_MY_ACCOUNT")}
                      </Button>
                    </>
                  )}
                </div>
                <div className={cn("awardit-appFooterTransactionRight", styles.right)}>
                  <Link className={styles.link} to={routes.accountTransactionsView.url}>
                    <HeadlessLastOrders />
                  </Link>
                  <div className={styles.section__bottom}>
                    <Button
                      className={cn("awardit-appFooterTransactionRightButton", styles.link, styles.centeredCta)}
                      to={routes.accountTransactionsView.url}
                    >
                      {t("FOOTER.LINKS.ALL_TRANSACTIONS")}
                    </Button>
                  </div>
                </div>
              </div>
            </Wrapper>

            {disableDividers !== null && disableDividers !== undefined && !disableDividers &&
              <div className={cn("awardit-appFooterDivider", styles.divider)} />
            }
          </>
        ) : (
          <>
            {appfooter && appfooter.hideCategories !== true &&
              <Wrapper>
                <div className={cn("awardit-appFooterCategories", styles.categories)}>
                  <h1>{t("HEADER.CATEGORIES")}</h1>
                  <div className={cn("awardit-appFooterCategorieLinks", styles.links)}>
                    {categories
                      .filter(item => !memberTargetList ||
                      memberTargetList.includes(item.awarditTargetId) ||
                      !item.awarditTargetId)
                      .map(category => (
                        <Link
                          key={category.name}
                          className={styles.link}
                          to={{
                            pathname: category.url,
                            state: { hint: { type: "category", category } },
                          }}
                        >
                          {category.name}
                        </Link>
                      ))}
                  </div>
                </div>
              </Wrapper>
            }
            {disableDividers !== null && disableDividers !== undefined && !disableDividers &&
              <div className={cn("awardit-appFooterDivider", styles.divider)} />
            }
          </>
        )}

        {(leftColumnHeading || rightColumnHeading) &&
          <Wrapper>
            <div className={cn("awardit-appFooterSection", styles.section)}>
              {leftColumnHeading &&
                <div className={cn("awardit-appFooterSectionLeft", styles.left)}>
                  <h2 className={styles.title}>{leftColumnHeading}</h2>
                  <p className={styles.description}>
                    {leftColumnText}
                  </p>
                  <Button className={cn("awardit-appFooterSectionLeftButton", styles.link)} to={leftColumnButtonLink}>
                    {leftColumnButtonText}
                  </Button>
                </div>
              }
              {rightColumnHeading &&
                <div className={cn("awardit-appFooterSectionRight", styles.right)}>
                  <h2 className={styles.title}>{rightColumnHeading}</h2>
                  {/* eslint-disable react/no-danger */}
                  <div
                    className={cn("typography", styles.description)}
                    dangerouslySetInnerHTML={{ __html: rightColumnText }}
                  />
                  {/* eslint-enable react/no-danger */}
                  <Button className={cn("awardit-appFooterSectionRightButton", styles.link)} to={rightColumnButtonLink}>
                    {rightColumnButtonText}
                  </Button>
                </div>
              }
            </div>
          </Wrapper>
        }

        {disableDividers !== null && disableDividers !== undefined && !disableDividers &&
          <div className={cn("awardit-appFooterDivider", styles.divider)} />
        }

        <Wrapper className={cn("awardit-appFooterTerms", styles.wrapper)}>
          <div className={styles.row}>
            <section className={cn("awardit-appFooterTermsContent", styles.content)}>
              <p>
                {footerText}
              </p>
              {termsLink &&
                <Button
                  className={cn("awardit-appFooterTermsButton", styles.link)}
                  to={termsLink.url}
                >
                  {termsLink.title}
                </Button>
              }
              {consentActivated &&
                <Button
                  className={styles.link}
                  onClick={() => sendMessage(setMode(MODE.COOKIE_CONSENT))}
                >
                  {t("FOOTER.NEED_HELP.COOKIES")}
                </Button>
              }
            </section>
          </div>
        </Wrapper>

        {disableDividers !== null && disableDividers !== undefined && !disableDividers &&
          <div className={cn("awardit-appFooterDivider", styles.divider)} />
        }

        <Wrapper className={cn("awardit-appFooterCopy", styles.copyWrapper)}>
          <section className={styles.copyright}>
            <div className={cn("awardit-appFooterCopyText", styles.row)}>
              <span>{copyrightText}</span>
            </div>
          </section>
        </Wrapper>
      </div>
    </footer>
  );
};

export default AppFooter;
