/* @flow */

import type { Form } from "shop-state/types";

import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import useDevice from "helpers/use-device";
import { StoreInfoContext, useClient } from "entrypoint/shared";
import { deleteFormData } from "queries";
import { addMessage } from "state/messages";
import { useData, useSendMessage } from "crustate/react";
import { Helmet } from "react-helmet-async";
import { Title } from "components/UiComponents";
import { useTranslate } from "@awardit/react-use-translate";
import Wrapper from "components/Wrapper";
import LoadingView from "components/LoadingView";
import Breadcrumbs from "components/Breadcrumbs";
import FormListItem from "./FormListItem";
import { getFormSubmits, getFormRows, getForm } from "state/forms";
import { FormSubmitsData, CustomerData, FormRowsData, FormData } from "data";

import styles from "./styles.scss";

const FormListView = () => {
  const { routes, content: { formListView } } = useContext(StoreInfoContext);
  const formList = useData(FormSubmitsData);
  const formData = useData(FormData);
  const customerData = useData(CustomerData);
  const formRowsData = useData(FormRowsData);
  const sendMessage = useSendMessage();
  const isDesktop = useDevice("gt", 1056);
  const client = useClient();
  const t = useTranslate();

  const formListRoute = routes.formListView;
  const formId = parseInt(formListView.formId, 10);
  const form: Form = formData.state === "LOADED" ? formData.data : {};
  const formListData = formList.state === "LOADED" ? formList.data : [];
  const rowData = formRowsData.state === "LOADED" ? formRowsData.data : [];

  const customer = customerData.state === "LOGGED_IN" ||
    customerData.state === "UPDATING" ? customerData.data : null;
  const customerId =
    customer !== null && customer !== undefined ? customer.awarditMemberId : "";

  useEffect(() => {
    if (formId !== null) {
      sendMessage(getFormSubmits(formId));
      sendMessage(getFormRows(formId));
      sendMessage(getForm(formId));
    }
  }, [formId]);

  const deleteFormItem = async id => {
    if (id !== null || id !== undefined) {
      const response = await client(deleteFormData, { id: (id: number) });

      if (response.deleteFormData.result === false) {
        sendMessage(addMessage(`DELETE_FORM_ERROR`, "error"));
      }
      else {
        sendMessage(addMessage(`DELETE_FORM_SUCCESS`, "success"));
        window.scroll({ top: 0, left: 0, behavior: "auto" });
        sendMessage(getFormSubmits(formId));
      }
    }
  };

  return (
    <Wrapper>
      <Helmet title={formListRoute.title} />
      <>
        <Breadcrumbs current={formListRoute.title} />
        <Title>{formListRoute.title}</Title>
        <Link className={styles.link} to={`/forms/${formListView.formId}?goPrevPage=true`}>
          <span>{formListView.linkText ? formListView.linkText : t("COMMON.ADD")}</span>
        </Link>
        <div className={styles.content}>
          {isDesktop ?
            <>
              <div className={styles.formList}>
                {formList.state === "LOADING" || formRowsData.state === "LOADING" ?
                  <LoadingView /> :
                  formListData.map((item, i) => (
                    <FormListItem
                      key={i}
                      item={item}
                      customerId={customerId}
                      formId={formId}
                      formRows={rowData}
                      deleteItem={() => deleteFormItem(item.id)} />
                  ))
                }
              </div>
              {/* eslint-disable react/no-danger */}
              <div
                dangerouslySetInnerHTML={{ __html: form.formtext }}
                className={styles.description}
              />
            </> :
            <>
              <div
                dangerouslySetInnerHTML={{ __html: form.formtext }}
                className={styles.description}
              />
              {/* eslint-enable react/no-danger */}
              <div className={styles.formList}>
                {formList.state === "LOADING" || formRowsData.state === "LOADING" ?
                  <LoadingView /> :
                  formListData.map((item, i) => (
                    <FormListItem
                      key={i}
                      item={item}
                      customerId={customerId}
                      formId={formId}
                      formRows={rowData}
                      deleteItem={() => deleteFormItem(item.id)} />
                  ))
                }
              </div>
            </>
          }
        </div>
      </>
    </Wrapper>
  );
};

export default FormListView;
