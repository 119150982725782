/* @flow */

import React, { useContext } from "react";
import { StoreInfoContext } from "entrypoint/shared";
import SupportIcon from "icons/support.svg";

import styles from "./styles.scss";

const CustomerServiceLink = () => {
  const {
    configuration: { helpdeskLink },
    content: { checkoutview },
  } = useContext(StoreInfoContext);

  const render = helpdeskLink &&
    checkoutview.customerServiceLink &&
    checkoutview.customerServiceHeading;

  if (!render) {
    return null;
  }

  return (
    <div className={styles.support}>
      <SupportIcon />
      <span>{checkoutview.customerServiceHeading}</span>
      <a
        className={styles.link} href={helpdeskLink} rel="noopener noreferrer"
        target="_blank"
      >
        {checkoutview.customerServiceLink}
      </a>
    </div>
  );
};

export default CustomerServiceLink;
