/* @flow */

import React, { useContext } from "react";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";
import RemoveIcon from "icons/close-small.svg";
import { useHistory } from "react-router-dom";

import styles from "./styles.scss";

type Props = {
  content: string,
  deleting: boolean,
  close: (animate: boolean) => void,
};

const MissingDetails = React.forwardRef<Props, any>(
  ({ content, deleting, close }: Props, ref) => {
    const t = useTranslate();
    const { push } = useHistory();
    const { routes } = useContext(StoreInfoContext);

    const action = () => {
      close(false);
      push(`${routes.accountView.url}`);
    };

    return (
      <div
        ref={ref}
        open={!deleting}
        className={cn(
          styles.message,
          { [styles.deleting]: deleting }
        )}
      >
        <div className={styles.itemInner}>
          <div className={styles.itemPadding}>
            <RemoveIcon className={styles.icon} onClick={close} />
            <p className={styles.title}>{t("MESSAGE.MISSING_DETAILS.HEADING")}</p>
            <p>{t("MESSAGE.MISSING_DETAILS." + content)}</p>
            <div className={styles.buttons}>
              <button
                type="button"
                className={styles.button}
                onClick={action}
              >
                {t("MESSAGE.MISSING_DETAILS.BUTTON_RIGHT")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default MissingDetails;
