/* @flow */

import type { AwarditAgreement } from "shop-state/types";

import React, { useEffect, useContext, useState } from "react";
import { useData, useSendMessage } from "crustate/react";
import { syncQuote } from "@crossroads/shop-state/quote";
import { QuoteData } from "data";
import { getQuoteData } from "state/quote";
import { useClient, StoreInfoContext } from "entrypoint/shared";
import useFormat from "helpers/use-format";

import LoadingView from "components/LoadingView";
import StripeAgreement from "components/StripeAgreement";
import PaymentMethodsCards from "components/CheckoutView/PaymentMethodsCards";

import { StripeCheckoutContainer } from "@crossroads/shop-views";
import { customer as customerQuery, awarditAgreement, quotePointsSetToMaximum } from "queries";

type Props = {
  open: boolean,
  setOpen: boolean => void,
};

const Step3 = ({ open, setOpen }: Props) => {
  const client = useClient();
  const sendMessage = useSendMessage();
  const storeInfo = useContext(StoreInfoContext);
  const { routes, content: { checkoutview } } = useContext(StoreInfoContext);
  const { formatPoints, formatPrice } = useFormat();
  const quoteData = useData(QuoteData);
  const quote = getQuoteData(quoteData);

  const [agreement, setAgreement] = useState<AwarditAgreement | null>(null);
  const { helpdeskLink, helpdeskTitle } = storeInfo.configuration;
  const helpdesk = { link: helpdeskLink, text: helpdeskTitle };

  const isPointsOnlyProducts = quoteData.state === "LOADED" &&
    quoteData.data.selectedPointPayment?.currency.max.incVat === 0 &&
    quoteData.data.selectedPointPayment.currency.min.incVat === 0;

  const noPointsSelected = quoteData.state === "LOADED" && quoteData.data &&
    quoteData.data.selectedPointPayment &&
    quoteData.data.selectedPointPayment.points.selected.exVat === 0;

  // Set selected point payment when landing on this page
  useEffect(() => {
    if (quoteData.state === "LOADED" && (!quoteData.data.selectedPointPayment || (isPointsOnlyProducts && noPointsSelected))) {
      (async () => {
        const { quotePointsSetToMaximum: { quote } } =
        await client(quotePointsSetToMaximum, { id: "awardit" });

        if (quote) {
          sendMessage(syncQuote(quote));
        }
      })();
    }
  }, [quoteData]);

  // Open summary when this route renders
  useEffect(() => {
    setOpen(true);
  }, [setOpen]);

  useEffect(() => {
    client(awarditAgreement).then(({ awarditAgreement }) => setAgreement(awarditAgreement));
  }, []);

  if (!quoteData.data) {
    return <LoadingView />;
  }

  const { selectedPointPayment } = quoteData.data;

  if (!selectedPointPayment) {
    return <LoadingView />;
  }

  return (
    <StripeCheckoutContainer
      open={open}
      setOpen={setOpen}
      formatPrice={formatPrice}
      formatPoints={formatPoints}
      client={client}
      storeInfo={storeInfo}
      quote={quote}
      quoteData={quoteData}
      helpdesk={helpdesk}
      checkoutRoute={routes.checkoutView}
      checkoutContent={checkoutview}
      customerQuery={customerQuery}
      agreement={<StripeAgreement agreement={agreement} />}
      paymentMethodsCards={<PaymentMethodsCards />}
      browserProcess={process.browser}
    />
  );
};

export default Step3;
