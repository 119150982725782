/* @flow */

import React, { useState, useEffect, useContext } from "react";
import { Route, Switch, useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import { AnalyticsContext } from "@crossroads/analytics";
import cn from "classnames";
import { useSkipPayment, canAffordCart, missingUserPoints } from "helpers/utils";
import { partnerRestrictionsInCart, pointsPriceByID } from "helpers/points";
import { useData } from "crustate/react";
import { getQuoteData } from "state/quote";
import { StoreInfoContext } from "entrypoint/shared";
import { QuoteData, CustomerData } from "data";
import Wrapper from "components/Wrapper";
import Button from "components/Button";
import Checkmark from "icons/check-small.svg";
import Redirect from "@crossroads/react-router-redirect";

import Cart from "components/CheckoutView/Cart";
import Step1 from "components/CheckoutView/Step1";
import Step2 from "components/CheckoutView/Step2";
import Step3 from "components/CheckoutView/Step3";

import styles from "./styles.scss";

type Props = {
  match: {
    params: {
      step?: string,
    },
  },
};

type Step = {
  id: string,
  key: number,
  link: string,
  title: string,
  disabled?: boolean,
};

type FooterProps = {
  steps: Array<Step>,
  step: number,
};

const CheckoutFooter = ({ steps, step }: FooterProps) => {
  const skipPayment = useSkipPayment();
  const { routes } = useContext(StoreInfoContext);

  return (
    <footer
      className={cn(styles.footer, "awardit-checkoutFooter")}
      style={{ transform: `translateY(${step >= 0 ? "0%" : "100%"})` }}
    >
      <div className={styles.steps}>
        {steps.filter(x => {
          // Hide second step if there's no need for point slider
          return x.id === "payment" ? !skipPayment : true;
        }).map(x => {
          return (
            <NavLink
              key={x.id}
              to={x.key > step ? "#" : `${routes.checkoutView.url}/${x.id}`}
              disabled={x.key > step}
              className={cn(
                styles.step,
                { [styles.active]: step === x.key },
                { [styles.completed]: step > x.key },
                { [styles.disabled]: x.key > step }
              )}
            >
              <Checkmark className={styles.checkmark} style={{ opacity: step > x.key ? 1 : 0 }} />
              <span>{x.link}</span>
            </NavLink>
          );
        })}
      </div>
    </footer>
  );
};

const CheckoutView = ({ match }: Props) => {
  const history = useHistory();
  const quoteState = useData(QuoteData);
  const quote = getQuoteData(quoteState);
  const customerData = useData(CustomerData);
  const gaContext = useContext(AnalyticsContext);
  const [open, setOpen] = useState(false);
  const [visited, setVisited] = useState([]);
  const { routes, content: { checkoutview } } = useContext(StoreInfoContext);
  const skipPayment = useSkipPayment();
  const [partnerSpecificPointDeficit, setPartnerSpecificPointDeficit] = useState({ partnerName: "", partnerPointDeficit: 0 });

  const steps: Array<Step> = [
    {
      id: "cart",
      key: 0,
      link: checkoutview.cartLink,
      title: checkoutview.cartTitle,
    },
    {
      id: "shipping",
      key: 1,
      link: checkoutview.shippingLink,
      title: checkoutview.shippingTitle,
    },
    {
      id: "payment",
      key: 2,
      link: checkoutview.paymentLink,
      title: checkoutview.paymentTitle,
    },
    {
      id: "overview",
      key: 3,
      link: checkoutview.overviewLink,
      title: checkoutview.overviewTitle,
      disabled: !(quote?.selectedPointPayment),
    },
  ];

  const currentStepObj = steps.find(s => s.id === match.params.step);
  const step = currentStepObj ? currentStepObj.key : 0;

  useEffect(() => {
    setVisited([...visited, step]);
  }, []);

  useEffect(() => {
    if (step === 0) {
      return;
    }

    if (step === 1) {
      if (quote) {
        gaContext.registerBeginCheckoutProcess(
          quote.items,
          quote.grandTotal.exVat
        );
      }
    }

    gaContext.registerCheckoutStep(step, currentStepObj?.id || "1");
  }, [step]);

  useEffect(() => {
    if (quote && partnerRestrictionsInCart(quote.items) && customerData.state === "LOGGED_IN") {
      const { items } = quote;
      const customer = customerData.data;
      const pointPayment = pointsPriceByID(quote.availablePointPayments, "awardit");
      const restrictedItem = items.find(item => Object.keys(item.product.attributes).includes("awarditRestrictionPid") && item.product.attributes.awarditRestrictionPid !== null);
      const restrictedItemPid = restrictedItem?.product.attributes.awarditRestrictionPid;

      const partner = customer.pointsPerPartner?.find(partner =>
        partner.partnerUserId === restrictedItemPid);
      const sumBalance = partner?.sumBalance;

      if (sumBalance !== null && sumBalance !== undefined &&
        pointPayment && (sumBalance < pointPayment.points.value.exVat)) {
        setPartnerSpecificPointDeficit({
          partnerPointDeficit: (sumBalance - pointPayment.points.value.exVat) * -1,
          partnerName: partner?.partnerName });
      }
      else {
        setPartnerSpecificPointDeficit({ partnerName: "", partnerPointDeficit: 0 });
      }
    }
  }, [quote]);

  const currentStep = steps[step] || steps[0];

  if (!quote || quoteState.state === "LOADING" || customerData.state !== "LOGGED_IN") {
    return null;
  }

  const canAfford = canAffordCart(quote);
  const missingPoints = missingUserPoints(quote);

  if (step !== 0 && (!canAfford || partnerSpecificPointDeficit.partnerPointDeficit > 0)) {
    return <Redirect to={`${routes.checkoutView.url}/cart`} />;
  }

  if (quoteState.state === "LOADED" && quote.items.length === 0) {
    return (
      <Wrapper variant="pageWrapper" className={cn("awardit-pageWrapper", styles.block, styles.empty)}>
        <header className={styles.header}>
          <h1 className={styles.headingEmpty}>{checkoutview.emptyCart}</h1>
        </header>

        <Button
          variant="primary"
          onClick={() => history.push(routes.redeemView.url)}
        >
          {checkoutview.continueShopping}
        </Button>
      </Wrapper>
    );
  }

  if (!quote) {
    return null;
  }

  return (
    <Wrapper variant="pageWrapper" className={cn("awardit-pageWrapper", styles.block)}>
      <header className={styles.header}>
        <div className={styles.title}>
          {currentStep.id === "cart" ? (
            <>
              <h1 className={styles.heading}>{currentStep.title}</h1>
              <span />
            </>
          ) : (
            <>
              <h1 className={styles.heading}>{checkoutview.checkoutTitle}</h1>
              <span>{currentStep.title}</span>
            </>
          )}
        </div>
      </header>

      <div className={styles.body}>
        <Switch>
          <Route
            exact
            path={`${routes.checkoutView.url}/cart`}
            render={() => (
              <Cart
                step={0}
                open={open}
                canAfford={canAfford}
                partnerSpecificPointDeficit={partnerSpecificPointDeficit}
                missingPoints={missingPoints}
                setOpen={setOpen}
                quoteData={quote} />
            )}
          />
          <Route exact path={`${routes.checkoutView.url}/shipping`} render={() => (<Step1 step={1} open={open} setOpen={setOpen} quoteData={quote} />)} />
          {!skipPayment && (
            <Route exact path={`${routes.checkoutView.url}/payment`} render={() => (<Step2 step={2} open={open} setOpen={setOpen} quoteData={quote} />)} />
          )}
          <Route exact path={`${routes.checkoutView.url}/overview`} render={() => (<Step3 step={3} open={open} setOpen={setOpen} quoteData={quote} />)} />
        </Switch>
      </div>

      <CheckoutFooter steps={steps} step={step} />

    </Wrapper>
  );
};

export default CheckoutView;
