/* @flow */

import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Input } from "@crossroads/ui-components";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";
import { mapBrands } from "helpers/appheader";
import useDetectKey from "helpers/use-detect-key";
import Wrapper from "components/Wrapper";
import SearchIcon from "icons/search.svg";
import ClearIcon from "icons/close-small.svg";

import styles from "./styles.scss";
import cn from "classnames";

type Brand = {
  name: string,
  url: string,
};

type Section = {
  section: string,
  children: Array<Brand>,
};

type Sections = { [key: string]: Section };

const BrandListView = () => {
  const t = useTranslate();
  useDetectKey(() => setSearchValue(""));
  const [searchValue, setSearchValue] = useState("");
  const { brands, routes } = useContext(StoreInfoContext);
  const brandLinks = mapBrands(routes.brandsView.url, brands).filter(brand =>
    brand.name.toLowerCase().includes(searchValue.toLowerCase()));
  const brandSections = brandLinks.reduce<Sections>((acc, brand) => {
    const section = brand.name[0].toUpperCase();
    if (!acc[section]) {
      acc[section] = { section, children: [brand] };
    }
    else {
      acc[section].children.push(brand);
    }

    return acc;
  }, {});

  return (
    <Wrapper variant="pageWrapper" className={cn("awardit-pageWrapper", "awardit-brandsWrapper")}>
      <Helmet
        title={t("HEADER.BRANDS")}
      />

      <div className={styles.header}>
        <h1 className={styles.title}>{t("HEADER.BRANDS")}</h1>
        <Input
          slotLeft={<SearchIcon className={styles.searchIcon} />}
          value={searchValue}
          placeholder={t("BRAND.SEARCH")}
          className={styles.searchInput}
          slotRight={searchValue &&
            <button className={styles.clear} aria-label={t("BRAND.CLEAR_SEARCH")} title={t("BRAND.CLEAR_SEARCH")} type="button" onClick={() => setSearchValue("")}>
              <ClearIcon className={styles.clearIcon} />
            </button>}
          onChange={e => setSearchValue(e.target.value)}
        />
      </div>

      <div className={styles.separator} />

      <div className={styles.body}>
        {Object.keys(brandSections).map(name => (
          <div key={name} className={styles.section}>
            <h2 className={styles.sectionTitle}>{name}</h2>
            <div className={styles.links}>
              {brandSections[name].children.map(b => (
                <Link
                  key={b.url}
                  className={styles.link}
                  to={{
                    pathname: b.url,
                    state: {
                      hint: {
                        type: "brand",
                        brand: b,
                      },
                    },
                  }}
                >
                  {b.name}
                </Link>
              ))}
            </div>
            <div className={styles.separator} />
          </div>
        ))}
      </div>
    </Wrapper>
  );
};

export default BrandListView;
