/* @flow */

import React, { useEffect, useRef, useState, useContext } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { Link } from "react-router-dom";
import cn from "classnames";
import { useData, useSendMessage } from "crustate/react";
import { useTranslate } from "@awardit/react-use-translate";
import { setMode, MODE } from "state/view-mode";
import { ViewModeData, TinkProvidersData } from "data";
import { useHideSubMenu } from "components/AppHeader/Large/SubMenu";
import Button from "components/Button";
import TinkProviders, { ACTIVE_DAYS_THRESHOLD } from "components/TinkProviders";
import useCookie from "helpers/use-cookie";
import { usePointsLabel } from "helpers/points";
import { StoreInfoContext } from "entrypoint/shared";
import CloseIcon from "icons/close-large.svg";

import styles from "./styles.scss";

type ModalProps = {
  overlayConfig: {
    color: string,
    opacity: number,
  },
};

type PropsModalInner = {
  overlayConfig: {
    color: string,
    opacity: number,
  },
  close: () => void,
};

const TinkProvidersModal = ({ overlayConfig }: ModalProps) => {
  const viewMode = useData(ViewModeData);
  const sendMessage = useSendMessage();
  const tinkProviders = useData(TinkProvidersData);
  const [provider, setProvider] = useState();
  const cookieOpts = { domain: `.${typeof window !== "undefined" ? window.location.host : ""}` };
  const {
    value: warningShown,
    setValue: setWarningShown,
    isRead,
  } = useCookie("provider_warning_shown", false, cookieOpts);

  useEffect(() => {
    if (tinkProviders.state === "LOADED") {
      const leastRemaining = tinkProviders.data.reduce((a, c) => {
        return (!a || c.remainingDays < a.remainingDays) ? c : a;
      }, null);

      if (leastRemaining && leastRemaining.remainingDays <= ACTIVE_DAYS_THRESHOLD) {
        setProvider(leastRemaining);
        sendMessage(setMode(MODE.TINK));
      }
    }
  }, [tinkProviders, sendMessage]);

  if (viewMode !== MODE.TINK || !provider || !isRead || (isRead && warningShown)) {
    return null;
  }

  return (
    <TinkProvidersModalInner
      overlayConfig={overlayConfig}
      provider={provider}
      close={() => {
        sendMessage(setMode(MODE.NORMAL));
        setWarningShown(true);
      }}
    />
  );
};

const TinkProvidersModalInner = ({
  close,
  overlayConfig }: PropsModalInner) => {
  const t = useTranslate();
  const label = usePointsLabel();
  const containerNode = useRef();
  const hideSubMenu = useHideSubMenu();
  const { routes: { accountTinkView } } = useContext(StoreInfoContext);

  const setClosed = () => {
    close();
  };

  useEffect(() => {
    const node = containerNode;

    if (node.current) {
      disableBodyScroll(node.current);
    }

    return () => {
      if (node.current) {
        enableBodyScroll(node.current);
      }
    };
  }, []);

  return (
    <div
      style={{ "--overlayColor": overlayConfig.color, "--overlayOpacity": overlayConfig.opacity }}
      className={cn(
        styles.modal,
        { [styles.subMenuHidden]: hideSubMenu }
      )}
    >
      <div className={styles.overlay} onClick={setClosed} />
      <div ref={containerNode} className={styles.container}>
        <Button className={styles.closeButton} onClick={setClosed}>
          <CloseIcon />
        </Button>
        <header>
          <h1>{t("TINK.MODAL.TITLE")}</h1>
          <p>
            {t("TINK.MODAL.SUBTITLE_PART1", { pointsLabel: label() })}
            &nbsp;
            {t("TINK.MODAL.SUBTITLE_PART2")}
            &nbsp;
            <Link
              to={accountTinkView.url}
              onClick={() => close()}
            >
              {t("TINK.MODAL.YOUR_ACCOUNT")}
            </Link>
          </p>
        </header>
        <div className={styles.body}>
          <TinkProviders hideActive />
        </div>
      </div>
    </div>
  );
};

export default TinkProvidersModal;
