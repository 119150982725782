/* @flow */

import React, { useState, useContext, useMemo, useEffect } from "react";
import cn from "classnames";
import inputStyles from "ui-components/Input.scss";
import { CustomerData } from "data";
import { useData } from "crustate/react";
import { SearchableDropdown, DropdownItem } from "@crossroads/ui-components";
import { StoreInfoContext } from "entrypoint/shared";
import Field from "components/Field";

import styles from "./styles.scss";

type Props = {
  type?: "billing" | "shipping",
  className?: string,
  setCustomerCountry?: (string) => void,
};

type ContactInfoProps = {
  type?: "billing" | "shipping" | "",
  className?: string,
};

export const ContactInfo = ({ type = "", className }: ContactInfoProps) => {
  const { content: { address } } = useContext(StoreInfoContext);

  const prefix = type ? `${type}.` : "";

  return (
    <div className={cn(styles.row, className)}>
      <div className={styles.s50}>
        <Field disabled={address.emailAsReadonly} name="email" label={address.email} />
      </div>
      <div className={styles.s50}>
        <Field name={`${prefix}telephone`} label={address.phone} />
      </div>
    </div>
  );
};

const Address = ({ type, className, setCustomerCountry }: Props) => {
  const { content: { address }, info: { countries, locale } } = useContext(StoreInfoContext);
  const sortedCountries = useMemo(() => {
    return countries.slice().sort((a, b) => {
      return a.name.localeCompare(
        b.name,
        locale.split("_")[0],
        { sensitivity: "base" }
      );
    });
  }, [countries, locale]);
  const [currentCountry, setCurrentCountry] = useState(sortedCountries[0].code);
  const customerData = useData(CustomerData);
  const currentCustomerCountry = customerData.state === "LOGGED_IN" &&
  customerData.data &&
  customerData.data.addresses.length > 0 &&
  customerData.data.addresses[0].country;

  useEffect(() => {
    if (currentCustomerCountry) {
      const customerCountryInAllowedCountries = sortedCountries.find(elem =>
        elem.code === currentCustomerCountry.code);

      setCurrentCountry(customerCountryInAllowedCountries ?
        customerCountryInAllowedCountries.code : sortedCountries[0].code);
    }
  }, []);

  useEffect(() => {
    if (setCustomerCountry &&
      currentCountry !== null &&
      typeof currentCountry === "string") {
      setCustomerCountry(currentCountry);
    }
  }, [currentCountry]);

  const prefix = type ? `${type}.` : "";

  return (
    <div className={className}>
      <div className={styles.row}>
        <div className={styles.s50}>
          <Field name={`${prefix}firstname`} label={address.firstname} />
        </div>
        <div className={styles.s50}>
          <Field name={`${prefix}lastname`} label={address.lastname} />
        </div>
      </div>

      {(address.showCompany || address.showOrg) &&
      <div className={styles.row}>
        {address.showCompany &&
        <div className={styles.s50}>
          <Field disabled={address.companyAsReadonly} name={`${prefix}company`} label={address.company} />
        </div>}

        {address.showOrg &&
        <div className={styles.s50}>
          <Field disabled={address.orgAsReadonly} name={`${prefix}awarditOrgNr`} label={address.org} />
        </div>}
      </div>
      }

      <div className={styles.row}>
        <div className={styles.s50}>
          <Field name={`${prefix}street.0`} label={address.street1} />
        </div>
        <div className={styles.s50}>
          <Field name={`${prefix}street.1`} label={address.street2} />
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.s33}>
          <Field name={`${prefix}postcode`} label={address.postcode} />
        </div>
        <div className={styles.s33}>
          <Field name={`${prefix}city`} label={address.city} />
        </div>
        <div className={styles.s33}>

          <label className={inputStyles.label}>{address.country}</label>
          <SearchableDropdown
            placeholder={address.search}
            className={styles.dropdown}
            name={`${prefix}countryCode`}
            value={currentCountry}
            onChange={setCurrentCountry}
          >
            {sortedCountries.map(x => (
              <DropdownItem key={x.code} value={x.code}>
                {x.name}
              </DropdownItem>
            ))}
          </SearchableDropdown>
        </div>
      </div>
    </div>
  );
};

export default Address;
