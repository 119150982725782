/* @flow */

import type { Transaction } from "shop-state/types";

import React, { useContext } from "react";
import cn from "classnames";
import { Button } from "@crossroads/ui-components";
import { StoreInfoContext } from "entrypoint/shared";
import { timestampToDateString } from "helpers/locale-utils";
import Points from "components/AccountView/TransactionsView/Points";
import Spinner from "components/Spinner";
import ClipIcon from "icons/clip.svg";
import { useTranslate } from "@awardit/react-use-translate";

import styles from "./styles.scss";

type TableLargeProps = {
  items: Array<Transaction>,
  setCurrentReceipt: string => void,
};

const TableLargeHead = () => {
  const { content: { transactionsview } } = useContext(StoreInfoContext);

  return (
    <thead>
      <tr>
        <th>{transactionsview.tableId}</th>
        <th>{transactionsview.tableTransaction}</th>
        {!transactionsview.hideTransactionSource && <th>{transactionsview.tableClub}</th>}
        {!transactionsview.hidePoints && <th>{transactionsview.tablePoints}</th>}
        {transactionsview.showRankpts && <th>{transactionsview.tableRankpts}</th>}
        <th>{transactionsview.tableDate}</th>
        {!transactionsview.hidePointsExpires && <th>{transactionsview.tablePointsExpires}</th>}
        {!transactionsview.hideReceipt && <th>{transactionsview.tableReceipt}</th>}
      </tr>
    </thead>
  );
};

const TableLarge = ({ items, setCurrentReceipt }: TableLargeProps) => {
  const { content: { transactionsview }, info: { code } } = useContext(StoreInfoContext);
  const t = useTranslate();

  // PartnerID's for Release Points & Michelin
  const partnerIdArray = ["releasefinans_sek", "michelin_nok", "michelin_sek", "michelin_dkk", "michelin_fin"].includes(code);

  return (
    <div className={styles.tableLarge}>
      <table className={styles.table}>
        <TableLargeHead />
        <tbody>
          {items.map((x, i) => (
            <tr key={`${x.id}-${i}`}>
              <td>{x.id}</td>
              {/* eslint-disable react/no-danger */}
              <td dangerouslySetInnerHTML={{ __html: x.type === 471 ? `${t("TRANSACTIONS.REFUND")} ${x.description}` : x.description }} />
              {/* eslint-enable react/no-danger */}
              {!transactionsview.hideTransactionSource && <td>{x.club}</td>}
              {!transactionsview.hidePoints &&
                <td className={cn({ [styles.negative]: x.flag === "DOWN" })}>
                  <Points points={x.points} />
                </td>
              }
              {transactionsview.showRankpts &&
                <td className={cn({ [styles.negative]: x.flag === "DOWN" })}>
                  <Points points={x.rank} />
                </td>
              }
              <td>{timestampToDateString(x.createdAt)}</td>
              {!transactionsview.hidePointsExpires &&
                <td>
                  {x.expiresAt !== null &&
                  timestampToDateString(x.expiresAt)}
                </td>
              }
              {!transactionsview.hideReceipt &&
                <td>
                  {((x.flag === "DEBIT" && x.type !== 468 && x.type !== 470) || (partnerIdArray && x.flag === "UP" && (x.type === 464 || x.type === 472))) && (
                    <Button
                      className={styles.receiptLink}
                      onClick={() => setCurrentReceipt(x.id)}
                    >
                      <ClipIcon />
                      <span>{transactionsview.receipt}</span>
                    </Button>
                  )}
                </td>
              }
            </tr>
          ))}
        </tbody>
      </table>
      <div className={styles.spacer} />
    </div>
  );
};

export const TableLargeHint = () => (
  <div className={cn(styles.tableHint, styles.tableHintLarge)}>
    <Spinner />
  </div>
);

export default TableLarge;
