/* @flow */

import type { Customer } from "shop-state/types";

import React, { useState, useContext, useEffect } from "react";
import cn from "classnames";
import { Foldable, Checkbox } from "@crossroads/ui-components";
import { useClient, StoreInfoContext } from "entrypoint/shared";
import { awarditSetCustomerMailnotify, customer as customerQuery } from "queries";

import styles from "components/AccountView/styles.scss";

const NewsletterSubscription = ({ customer }: { customer: Customer }) => {
  const client = useClient();
  const [open, setOpen] = useState(false);
  const [disable, setDisable] = useState(false);
  const [state, setState] = useState(customer.awardit.mailnotify);
  const [firstCondition, setFirstCondition] = useState(customer.awardit.mailnotify);
  const [secondCondition, setSecondCondition] = useState(customer.awardit.mailnotify);
  const { content: { accountview, checkoutview } } = useContext(StoreInfoContext);

  useEffect(() => {
    setState(customer.awardit.mailnotify);
  }, [customer.awardit.mailnotify]);

  const handleChange = () => {
    if (open) {
      setState(!state);
    }
  };

  const handleFirstConditionChange = () => {
    setFirstCondition(!firstCondition);
  };

  const handleSecondConditionChange = () => {
    setSecondCondition(!secondCondition);
  };

  const submit = e => {
    e.preventDefault();

    client(awarditSetCustomerMailnotify, {
      value: state,
    }).then(() => {
      client(customerQuery).then(() => {
      });
    });

    setOpen(false);
  };

  useEffect(() => {
    if (!accountview.firstCondition && !accountview.secondCondition) {
      setDisable(false);
    }

    if (accountview.firstCondition && !accountview.secondCondition) {
      if (state && !firstCondition) {
        setDisable(true);
      }
      else {
        setDisable(false);
      }
    }

    if (accountview.firstCondition && accountview.secondCondition) {
      if (state && (!firstCondition || !secondCondition)) {
        setDisable(true);
      }
      else {
        setDisable(false);
      }
    }
  }, [state, firstCondition, secondCondition]);

  return (
    <div className={styles.newsletter}>
      <form onSubmit={submit}>
        <header className={cn(styles.boxHeader, styles.boxHeaderConsent)}>
          <h2 className={styles.boxHeading}>
            {accountview.titleMail}
          </h2>

          {!open ?
            <button
              type="button" onClick={e => {
                e.preventDefault();
                setOpen(true);
              }}
            >
              <span>{checkoutview.edit}</span>
            </button> :
            <button
              className={disable ? styles.disabled : ""}
              disabled={disable}
              type="submit"
            >
              <span>{accountview.saveAndClose}</span>
            </button>
          }
        </header>

        {!open &&
        <div className={styles.boxData}>
          <Checkbox
            disabled
            name="mailnotifyvalue"
            className={styles.dataCheckbox}
            value={state}
            onChange={handleChange}
          >
            {accountview.labelMail}
          </Checkbox>
        </div>
        }

        <Foldable open={open}>
          <section className={styles.boxBody}>
            <Checkbox
              name="mailnotify"
              className={styles.checkbox}
              value={state}
              onChange={handleChange}
            >
              {accountview.labelMail}
            </Checkbox>

            {state &&
            <div>

              {/* eslint-disable react/no-danger */}
              {accountview.htmlMail &&
                <div
                  className={styles.consentContent}
                  dangerouslySetInnerHTML={{ __html: accountview.htmlMail }}
                />
              }
              {/* eslint-enable react/no-danger */}

              {accountview.firstCondition &&

              <div className={styles.checkboxContainer}>
                <Checkbox
                  required
                  name="firstCondition"
                  className={styles.checkbox}
                  value={firstCondition}
                  onChange={handleFirstConditionChange}
                >
                  {accountview.firstCondition}
                </Checkbox>
              </div>
              }

              {accountview.secondCondition &&

              <div className={styles.checkboxContainer}>
                <Checkbox
                  required
                  name="secondCondition"
                  className={styles.checkbox}
                  value={secondCondition}
                  onChange={handleSecondConditionChange}
                >
                  {accountview.secondCondition}
                </Checkbox>
              </div>
              }
            </div>
            }

          </section>
        </Foldable>
      </form>
    </div>
  );
};

export default NewsletterSubscription;
