/* @flow */

import React, { useContext } from "react";
import { useData } from "crustate/react";
import { StoreInfoContext } from "entrypoint/shared";
import NotFoundView from "components/NotFoundView";
import Wrapper from "components/Wrapper";
import NewsListItem from "components/NewsListItem";
import HeroIntro from "components/HeroIntro";
import { NewsPostsData } from "data";
import { formatTimestampToLocaleDate } from "helpers/locale-utils";

import styles from "./styles.scss";

type NewsViewProps = {
  title: string,
};

const NewsView = ({ title }: NewsViewProps) => {
  const { info, content: { newsview: content, heroIntroView } } = useContext(StoreInfoContext);
  const dateFormat = formatTimestampToLocaleDate(info.locale);
  const newsPosts = useData(NewsPostsData);

  if (newsPosts.state !== "LOADED") {
    return null;
  }

  const newsPost = newsPosts.data.items.find(post => post.title === title);

  if (!newsPost) {
    return <NotFoundView />;
  }

  const newsPostsItems = newsPosts.data.items
    .filter(x => x.title !== newsPost.title)
    .map(x => ({ ...x, publishedAt: dateFormat(x.publishedAt) }));

  return (
    <>
      {heroIntroView.toggle &&
      <HeroIntro
        title={newsPost.title}
      />
      }
      <Wrapper variant="pageWrapper" className="awardit-pageWrapper">
        <div className={styles.split}>
          <div className={styles.left}>
            {!heroIntroView.toggle &&
            <h1 className={styles.title}>{newsPost.title}</h1>
            }
            {/* eslint-disable react/no-danger */}
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{ __html: newsPost.content }}
            />
            {/* eslint-enable react/no-danger */}
          </div>
          <div className={styles.right}>
            <div className={styles.items}>
              {newsPostsItems.length > 0 &&
              <h2 className={styles.itemsHeading}>{content.moreNewsHeading}</h2>}

              {newsPostsItems.map((x, i) => <NewsListItem key={i} item={x} />)}
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default NewsView;
