/* @flow */

import React, { useContext } from "react";
import { StoreInfoContext } from "entrypoint/shared";

import Wrapper from "components/Wrapper";
import { useTranslate } from "@awardit/react-use-translate";
import { useData, useSendMessage } from "crustate/react";
import { Helmet } from "react-helmet-async";
import QuizListItem from "../QuizListItem";
import { QuizListData } from "data";
import styles from "./styles.scss";
import { getQuizList } from "state/quiz-list";
import Breadcrumbs from "components/Breadcrumbs";
import ErrorView from "components/ErrorView";
import { Title } from "components/UiComponents";
import HeroIntro from "components/HeroIntro";
import cn from "classnames";

const QuizListView = () => {
  const t = useTranslate();
  const quizListData = useData(QuizListData);
  const quizList = quizListData.state === "LOADED" ? quizListData.data : [];
  const sendMessage = useSendMessage();
  const { content: { quizlistview, heroIntroView }, routes } = useContext(StoreInfoContext);

  const reload = () => {
    sendMessage(getQuizList());
  };

  if (quizListData.state === "ERROR") {
    return (
      <ErrorView>
        <button type="button" onClick={reload}>Reload</button>
      </ErrorView>
    );
  }

  return (
    <>
      {heroIntroView.toggle && Object.keys(quizlistview).length !== 0 &&
        <HeroIntro
          title={quizlistview && quizlistview.heading}
          description={quizlistview && quizlistview.introText}
        />
      }
      <Wrapper variant="pageWrapper" className={cn("awardit-pageWrapper", styles.wrapper)}>
        <Helmet
          title={routes.quizList.title}
        />

        <Wrapper className={styles.breadcrumbsWrapper}>
          <Breadcrumbs current={routes.quizList.title} />
        </Wrapper>

        {quizListData.state === "LOADED" && quizList.length === 0 &&
          <p>{t("QUIZ.EMPTY")}</p>
        }

        {!heroIntroView.toggle && quizlistview &&
        <>
          {quizlistview && quizlistview.heading &&
            <Title>{quizlistview.heading}</Title>
          }
          {/* eslint-disable react/no-danger */}
          {quizlistview && quizlistview.introText &&
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: quizlistview.introText }}
            />
          }
          {/* eslint-enable react/no-danger */}
        </>
        }

        <div className={styles.block}>
          <div className={styles.items}>

            {quizList.length > 0 && quizList.map(quiz => {
              if (quiz.catname === "welcomeQuiz" && quiz.answered !== 0) {
                return null;
              }

              return <QuizListItem key={quiz.id} item={quiz} />;
            })}

          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default QuizListView;
