/* @flow */

import React, { useState, useEffect, useContext, useMemo } from "react";
import cn from "classnames";
import { useHistory } from "react-router-dom";
import { useData, useSendMessage } from "crustate/react";
import { TinkTransactionsData, TinkProvidersData, CustomerData } from "data";
import { StoreInfoContext } from "entrypoint/shared";
import { clearMessages, addMessage, addMessageTranslated } from "state/messages";
import { parseParams } from "helpers/location-search-string";
import { PAGE_SIZE, load as loadTransactions } from "state/tink-transactions";
import { load as loadProviders } from "state/tink-providers";
import { Lightbox } from "@crossroads/ui-components";
import Wrapper from "components/Wrapper";
import ErrorView from "components/ErrorView";
import Back from "components/AccountView/Back";
import Button from "components/Button";
import TableLarge, { TableLargeHint } from "components/AccountView/TinkView/TableLarge";
import TableSmall, { TableSmallHint } from "components/AccountView/TinkView/TableSmall";
import TinkProviders from "components/TinkProviders";
import ChevronIcon from "icons/chevron-mini.svg";
import { usePointsLabel } from "helpers/points";

import styles from "./styles.scss";

type TinkRedirectButtonProps = {
  buttonText: string,
};

type IframeProps = {
  onClose: (error?: string) => void,
  onComplete: (message: string) => void,
};

const TinkRedirectButton = ({ buttonText }: TinkRedirectButtonProps) => {
  const customer = useData(CustomerData);
  const { info: { partnerId, host } } = useContext(StoreInfoContext);

  if (customer.state !== "LOGGED_IN") {
    return null;
  }

  const { awarditMemberId } = customer.data;

  return (
    <a href={"https://" + host + "/tinkback?muid=" + awarditMemberId + "&puid=" + partnerId}>
      <Button variant="primary">{buttonText}</Button>
    </a>
  );
};

const TinkIframe = ({ onComplete, onClose }: IframeProps) => {
  const sendMessage = useSendMessage();
  const customer = useData(CustomerData);
  const { info: { partnerId }, info: { host } } = useContext(StoreInfoContext);

  useEffect(() => {
    const loginCallback = window.addEventListener("message", event => {
      if (event.origin !== "https://" + host) {
        return;
      }

      const { retcode, message } = JSON.parse(event.data);

      // Close event
      if (retcode === 7) {
        return onClose();
      }

      // Auth error
      if (retcode === 8) {
        return onClose(message);
      }

      // Success
      if (retcode === 1) {
        return onComplete(message);
      }
    }, false);

    return () => window.removeEventListener("message", loginCallback);
  }, [sendMessage, onClose, onComplete]);

  if (customer.state !== "LOGGED_IN") {
    return null;
  }

  const { awarditMemberId } = customer.data;

  return (
    <iframe
      src={"https://" + host + "/tinkback?muid=" + awarditMemberId + "&puid=" + partnerId}
      className={styles.iframe}
    />
  );
};

const TinkView = () => {
  const { routes, content: { tinkview } } = useContext(StoreInfoContext);
  const customer = useData(CustomerData);
  const sendMessage = useSendMessage();
  const tinkTransactions = useData(TinkTransactionsData);
  const tinkProviders = useData(TinkProvidersData);
  const [iframeOpen, setIframeOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [current, setCurrent] = useState<number | null>(null);
  const { location, replace } = useHistory();
  const params = useMemo(() => parseParams(location.search), [location.search]);
  const { info: { partnerId }, info: { host } } = useContext(StoreInfoContext);
  const { awarditMemberId } = customer.state === "LOGGED_IN" ? customer.data : {};
  const label = usePointsLabel();

  // Handle Tink callback parameter
  useEffect(() => {
    if (typeof params.result === "string") {
      const resultInt = parseInt(params.result, 10);
      sendMessage(addMessage({
        translationKey: `TINK_CALLBACK.${resultInt < 5 ? resultInt : "UNKNOWN"}`,
        variable: {
          ...(resultInt === 1) && { pointsLabel: label() },
        },
      },
      resultInt === 1 ? "success" : "error"
      ));

      replace(location.pathname);
    }
  }, [params]);

  useEffect(() => {
    if (page > 1) {
      sendMessage(loadTransactions(page));
    }
  }, [sendMessage, page]);

  if (tinkTransactions.state === "ERROR" || tinkProviders.state === "ERROR") {
    return <ErrorView />;
  }

  return (
    <Wrapper>
      <Lightbox className={styles.lightbox} open={iframeOpen} setOpen={setIframeOpen}>
        <TinkIframe
          onClose={error => {
            if (error) {
              sendMessage(clearMessages());
              sendMessage(addMessageTranslated(error, "error"));
            }

            setIframeOpen(false);
          }}
          onComplete={message => {
            sendMessage(clearMessages());
            sendMessage(addMessageTranslated(message, "success"));
            sendMessage(loadTransactions());
            sendMessage(loadProviders());
            setIframeOpen(false);
          }}
        />
      </Lightbox>

      {!routes.earnViewSubpage.toggle && !routes.earnViewSubpage.url &&
      !routes.earnViewSubpage2.toggle && !routes.earnViewSubpage2.url &&
        <Back />
      }

      <h1>{tinkview.heading}</h1>
      <div className={styles.connect}>
        {/* eslint-disable react/no-danger */}
        <p
          dangerouslySetInnerHTML={{ __html: tinkview.description }}
        />
        {/* eslint-enable react/no-danger */}
        {tinkview.useIframeEmbed ?
          <Button variant="primary" onClick={() => setIframeOpen(true)}>{tinkview.connectProviderButtonText}</Button> :
          <TinkRedirectButton buttonText={tinkview.connectProviderButtonText} />
        }
      </div>

      <div>
        {Array.isArray(tinkProviders.data) && tinkProviders.data.length > 0 &&
          <>
            <header className={styles.header}>
              <h2>{tinkview.connectedProvidersHeading}</h2>
              {tinkview.useIframeEmbed ?
                <span className={cn("link", styles.link)} role="button" onClick={() => setIframeOpen(true)}>
                  <span>{tinkview.addProviderText}</span>
                  <ChevronIcon />
                </span> :
                <a href={"https://" + host + "/tinkback?muid=" + awarditMemberId + "&puid=" + partnerId}>
                  <span>{tinkview.addProviderText}</span>
                  <ChevronIcon />
                </a>
              }
            </header>

            <TinkProviders className={styles.providers} />

            {(tinkProviders.state === "REMOVING" || tinkTransactions.state === "LOADING") &&
              <>
                <header className={styles.header}>
                  <h2>{tinkview.tableHeading}</h2>
                </header>
                <TableLargeHint numItems={PAGE_SIZE} />
                <TableSmallHint numItems={PAGE_SIZE} />
              </>
            }

            {tinkProviders.state !== "REMOVING" && tinkTransactions.state !== "LOADING" && tinkTransactions.data.length > 0 ?
              <>
                <header className={styles.header}>
                  <h2>{tinkview.tableHeading}</h2>
                </header>
                <TableLarge items={tinkTransactions.data} />
                <TableSmall
                  items={tinkTransactions.data}
                  current={current}
                  onClick={x => setCurrent(current !== x ? x : null)}
                />
                <Button
                  className={styles.nextPage}
                  variant="primary"
                  loading={tinkTransactions.state === "FETCHING"} onClick={() => setPage(page + 1)}
                >
                  {tinkview.loadMoreTransactionsText}
                </Button>
              </> : <span>{tinkview.noTransactions}</span>
            }
          </>
        }
      </div>
    </Wrapper>
  );
};

export default TinkView;
