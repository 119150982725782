/* @flow */

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { StoreInfoContext } from "entrypoint/shared";
import SystemMessages from "components/SystemMessages";
import CookieConsent from "components/CookieConsent";
import { useSendMessage } from "crustate/react";
import { getFavicons } from "helpers/get-meta";
import { CmsData } from "data";
import { setMode, MODE } from "state/view-mode";
import { useTranslate } from "@awardit/react-use-translate";
import Button from "components/Button";
import Wrapper from "components/Wrapper";
import Logo from "components/AppHeader/Logo";

import cn from "classnames";
import styles from "./styles.scss";

type Props = {
  children: React$Node,
  location: Location,
};

const GuestApp = ({ children, location }: Props) => {
  const {
    info,
    configuration,
    routes,
    content: { loginview, appfooter },
  } = useContext(StoreInfoContext);

  const style = {
    background: loginview.imageBackground ? `url(${loginview.imageBackground})` : "",
    backgroundColor: "var(--primaryAlt)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    backgroundSize: "cover",
  };

  const sendMessage = useSendMessage();
  const t = useTranslate();
  const loginViewLogoSrc = loginview.logo || "";
  const consentActivated = (configuration.showCookieConsent !== null ||
    configuration.showCookieConsent !== undefined) &&
    configuration.showCookieConsent === true;

  return (
    <CmsData.Provider url="cookie-consent">
      <div style={style} className={styles.block}>
        <Helmet
          titleTemplate={`${configuration.pageTitlePrefix || ""} %s ${configuration.pageTitleSuffix || ""}`}
          link={getFavicons(configuration)}
          htmlAttributes={{ lang: info.locale.slice(0, 2) }}
        />

        <header className={styles.header}>
          <Wrapper className={styles.headerWrapper}>
            <Link to="/">
              <Logo
                width={configuration.logoWidthLarge}
                isInverted={false}
                loginViewLogo={loginViewLogoSrc}
              />
            </Link>
          </Wrapper>
        </header>
        <div className={styles.wrapper}>
          {children}
          <SystemMessages />
          {location.pathname === routes.loginView.url &&
            <div><CookieConsent /></div>
          }
        </div>
        <div className={styles.footer}>
          <div className={styles.footerInner}>
            {loginview.customerServiceButtonUrl && (
              <span>{appfooter.copyrightText}</span>
            )}

            <nav>
              {consentActivated &&
              <Button
                className={cn(
                  { [styles.spacing]: loginview.customerServiceButtonUrl },
                  styles.cookieButton
                )}
                onClick={() => sendMessage(setMode(MODE.COOKIE_CONSENT))}
              >{t("FOOTER.NEED_HELP.COOKIES")}
              </Button>
              }
              {loginview.customerServiceButtonUrl && (
                <a href={loginview.customerServiceButtonUrl}>
                  {loginview.customerServiceButtonText}
                </a>
              )}
            </nav>
          </div>
        </div>
      </div>
    </CmsData.Provider>
  );
};

export default GuestApp;
