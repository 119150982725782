/* @flow */

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { StoreInfoContext } from "entrypoint/shared";
import ChevronIcon from "icons/chevron-mini.svg";

import styles from "./styles.scss";

const Back = () => {
  const { content: { transactionsview }, routes } = useContext(StoreInfoContext);

  return (
    <Link className={styles.back} to={routes.accountView.url}>
      <ChevronIcon />
      <span>{transactionsview.goBackText}</span>
    </Link>
  );
};

export default Back;
