/* @flow */

import type { QuestionnaireQuestion, QuestionnaireQuestionInput } from "shop-state/types";

import React, { useState, useEffect, useContext } from "react";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import Button from "components/Button";
import Wrapper from "components/Wrapper";
import ArrowIcon from "icons/arrow.svg";
import ErrorIcon from "icons/close-mini.svg";
import SuccessIcon from "icons/check-small.svg";
import { Foldable } from "@crossroads/ui-components";
import { useData, useSendMessage } from "crustate/react";
import { QuestionnaireData } from "data";
import LoadingView from "components/LoadingView";
import ErrorView from "components/ErrorView";
import { submitQuestionnaire } from "state/questionnaire";
import { StoreInfoContext } from "entrypoint/shared";
import Options from "components/QuestionnaireView/options";

import styles from "./styles.scss";

type PaginationProps = {
  step: number,
  setStep: number => void,
  stepCount: number,
  progressedTo: number,
  validations: Array<boolean>,
};

const Pagination = ({ step, setStep, stepCount, progressedTo, validations }: PaginationProps) => (
  <div className={styles.pagination}>
    <Button
      className={styles.prev}
      disabled={step <= 1}
      onClick={() => setStep(step - 1)}
    >
      <ArrowIcon />
    </Button>

    <div className={styles.pagination__numbers}>
      {[...new Array(stepCount)].map((_, i) => (
        <Button
          key={i}
          disabled={progressedTo < i}
          className={cn(styles.pagination__step, { [styles.active]: step === i + 1 })}
          onClick={() => progressedTo >= i && setStep(i + 1)}
        >
          {!validations[i] && progressedTo > i &&
            <ErrorIcon className={styles.errorIcon} />
          }

          {validations[i] && progressedTo > i &&
            <SuccessIcon className={styles.successIcon} />
          }

          {progressedTo <= i && i + 1}
        </Button>
      ))}
    </div>

    <Button
      className={styles.next}
      disabled={step >= stepCount || progressedTo < step}
      onClick={() => progressedTo >= step && setStep(step + 1)}
    >
      <ArrowIcon />
    </Button>
  </div>
);

const answerValid = (answer?: Array<string>) => {
  if (!answer) {
    return false;
  }

  return answer.filter(a => typeof a === "string" && a.length > 0).length > 0;
};

const QuestionnaireView = () => {
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const [step, setStep] = useState(1);
  const [answers, setAnswers] = useState<{ [number]: Array<string> }>({});
  const questionnaireData = useData(QuestionnaireData);
  const questionnaire = questionnaireData.data ? questionnaireData.data : null;
  const questions: Array<QuestionnaireQuestion> = questionnaire ? questionnaire.questions : [];
  const currentQuestion = questions.length >= step ? questions[step - 1] : null;
  const validations = questions.map(q => answerValid(answers[q.id]));
  const status = questionnaire?.status || "";
  const answered = status === "OPEN" &&
    questions.length > 0 &&
    questions.filter(q => typeof q.value === "string").length > 0;
  const [processing, setProcessing] = useState(false);
  const { content: { questionnaireview } } = useContext(StoreInfoContext);

  useEffect(() => {
    if (answered) {
      const _answers = questions.reduce((acc, curr) => {
        if (curr.value) {
          acc[curr.id] = curr.value.split(";");
        }

        return acc;
      }, {});

      setAnswers(_answers);
    }
  }, [answered]);

  const handleNextButtonClick = () => {
    if (step === questions.length) {
      setProcessing(true);
      submit();
    }
    else {
      setStep(step + 1);
    }
  };

  if (questionnaireData.state === "ERROR") {
    return <ErrorView />;
  }

  if (questionnaireData.state === "LOADING") {
    return <LoadingView />;
  }

  const submit = () => {
    if (answered || status === "CLOSED") {
      return;
    }

    const responses: Array<QuestionnaireQuestionInput> = Object.keys(answers)
      .reduce((acc: Array<QuestionnaireQuestionInput>, k: string) => {
        const key = Number.parseInt(k, 10);
        return [...acc, { id: key, value: answers[key] }];
      }, []);
    const d = new Date();
    const time = Number.parseInt(d.toISOString().split("T")[0].replace(/-/g, ""), 10);

    if (questionnaire) {
      sendMessage(submitQuestionnaire(questionnaire.id, time, responses));
    }
  };

  // @TODO: use description and title
  const { title } = questionnaireData.data;

  if (questionnaireData.state === "SUBMITTED") {
    return (
      <Wrapper variant="pageWrapper" className="awardit-pageWrapper">
        <div className={styles.success}>
          <div className={styles.success__left}>
            {questionnaireData.result === "SUCCESS" &&
              <>
                <h2>{questionnaireview.successHeading}</h2>
                {/* eslint-disable react/no-danger */}
                <p
                  dangerouslySetInnerHTML={{ __html: questionnaireview.successText }}
                />
                {/* eslint-enable react/no-danger */}
              </>
            }
            {questionnaireData.result === "ALREADY_ANSWERED" &&
              <h2>{t("QUIZ.SUCCESS.ALREADY_ANSWERED")}</h2>
            }
            {questionnaireData.result === "COULD_NOT_SAVE" &&
              <h2>{t("QUIZ.SUCCESS.COULD_NOT_SAVE")}</h2>
            }
            {questionnaireData.result === "QUESTIONNAIRE_CLOSED" &&
              <h2>{t("QUIZ.SUCCESS.QUESTIONNAIRE_CLOSED")}</h2>
            }
            <Button
              className={styles.backButton}
              variant="primary"
              to="/"
            >
              {t("QUIZ.SUCCESS.BACK")}
            </Button>
          </div>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper className="awardit-pageWrapper" variant="thin">
      <h1 className={styles.title}>{title}</h1>

      {answered &&
        <h3 className={styles.subTitleClosed}>{t("QUIZ.ALREADY_ANSWERED")}</h3>
      }
      {!answered && status === "CLOSED" &&
        <h3 className={styles.subTitleClosed}>{t("QUIZ.CLOSED")}</h3>
      }

      {/* eslint-disable react/no-danger */}
      {questionnaire?.descriptionHTML &&
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: questionnaire.descriptionHTML }}
        />
      }
      {/* eslint-enable react/no-danger */}

      {questions.length > 1 &&
        <Pagination
          step={step}
          setStep={x => {
            if (processing) {
              return;
            }

            setStep(x);
          }}
          stepCount={questions.length}
          validations={validations}
          progressedTo={Object.keys(answers).length}
        />
      }

      {currentQuestion &&
        <div>
          <div className={styles.header}>
            <h2>{t("QUIZ.HEADING", { step, of: questions.length })}</h2>
            <p>{currentQuestion.title}</p>
          </div>

          <div className={styles.questions}>
            <Options
              question={currentQuestion}
              answers={answers[currentQuestion.id] || []}
              setAnswer={x => {
                if (answered || status === "CLOSED" || processing) {
                  return;
                }

                setAnswers({ ...answers, [currentQuestion.id]: x });
              }}
            />

            {(!answered || status === "CLOSED" || step < questions.length) &&
              <Foldable
                key={step}
                className={styles.next}
                open={answerValid(answers[currentQuestion.id])}
              >
                <Button
                  className={styles.nextButton}
                  variant="primary"
                  loading={processing}
                  disabled={
                    (step === questions.length && validations.some(x => x === false)) ||
                    processing
                  }
                  onClick={handleNextButtonClick}
                >
                  {t(`QUIZ.${step < questions.length ? "NEXT" : "SUBMIT"}`)}
                </Button>
              </Foldable>
            }

          </div>
        </div>
      }
    </Wrapper>
  );
};

export default QuestionnaireView;
