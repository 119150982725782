/* @flow */

import { useEffect, useContext } from "react";
import { useSendMessage, useData } from "crustate/react";
import { StoreInfoContext } from "entrypoint/shared";
import { CustomerData } from "data";
import { getCustomerData } from "helpers/utils";
import { addMessage } from "state/messages";

const useNotifyMissingAccountDetails = () => {
  const sendMessage = useSendMessage();
  const customer = getCustomerData(useData(CustomerData));
  const {
    content: {
      accountview: content,
    },
  } = useContext(StoreInfoContext);

  useEffect(() => {
    // Always notify if phonenumber missing
    if (!customer?.addresses[0].telephone) {
      sendMessage(addMessage("NUMBER_NOT_FOUND", "account-details-missing"));
    }

    if (content.showDob && content.dobNotify && !customer?.dob) {
      sendMessage(addMessage("DOB_NOT_FOUND", "account-details-missing"));
    }

    if (content.firstnameNotify && !customer?.firstname) {
      sendMessage(addMessage("FIRSTNAME_NOT_FOUND", "account-details-missing"));
    }

    if (content.lastnameNotify && !customer?.lastname) {
      sendMessage(addMessage("LASTNAME_NOT_FOUND", "account-details-missing"));
    }

    if (content.emailNotify && !customer?.email) {
      sendMessage(addMessage("EMAIL_NOT_FOUND", "account-details-missing"));
    }

    if (content.showGender && content.genderNotify && !customer?.gender) {
      sendMessage(addMessage("GENDER_NOT_FOUND", "account-details-missing"));
    }

    if (content.orgnrNotify && !customer?.addresses[0].awarditOrgNr) {
      sendMessage(addMessage("ORGNR_NOT_FOUND", "account-details-missing"));
    }
  }, []);
};

export default useNotifyMissingAccountDetails;
