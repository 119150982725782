/* @flow */

import type { History, Location } from "react-router";
import { useEffect, useRef, useContext } from "react";
import { StoreInfoContext } from "entrypoint/shared";

// type Ref<T> = {|current: T|};

type OnPush<T> = (history: History, location: Location) => T;
type OnPop<T> = (history: History, location: Location, metadata: ?T) => void;

export const useOnNavigation = <T>(history: History, onPush: OnPush<T>, onPop: OnPop<T>) => {
  const storeInfo = useContext(StoreInfoContext);
  const { routes } = storeInfo;
  const lastKey = useRef(history.location.key);
  const log = useRef([]);

  useEffect(() => history.listen(location => {
    const { current: currentKey } = lastKey;

    // Prevent scroll to top on filters as query params
    // Todo: Find other solution and handle for Category also
    if (history.location.pathname === routes.allProductsView.url &&
      history.location.search.length > 0) {
      return;
    }

    if (history.action === "PUSH") {
      // Ensure we actually are pushing after the current key, clear from the
      // end until we find currentKey
      if (currentKey) {
        for (let i = log.current.length - 1; i >= 0; i--) {
          if (log.current[i].key !== currentKey) {
            log.current.pop();

            break;
          }
        }
      }

      log.current.push({
        key: lastKey.current,
        data: onPush(history, location),
      });
    }
    else if (history.action === "POP") {
      let metadata = null;

      // Find the metadata for the key
      for (let i = log.current.length - 1; i >= 0; i--) {
        if (log.current[i].key === location.key) {
          metadata = log.current[i];

          break;
        }
      }

      onPop(history, location, metadata ? metadata.data : null);
    }

    lastKey.current = location.key;
  }), [lastKey, log, history, onPush, onPop]);
};

const scrollToTop = () => {
  const prevPosition = window.scrollY;

  window.scrollTo(0, 0);

  return prevPosition;
};

const scrollToMetadata = (history, location, prevPosition: ?number) => {
  window.scrollTo(0, prevPosition || 0);
};

export const usePreserveScrollPosition = (history: History) =>
  useOnNavigation(history, scrollToTop, scrollToMetadata);

