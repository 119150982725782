/* @flow */

import type { SearchSuggestion } from "shop-state/types";

import React, { useState, useEffect, useRef, useContext } from "react";
import { useClient, StoreInfoContext } from "entrypoint/shared";
import { searchSuggestions as searchSuggestionsQuery } from "queries";
import { debounce } from "diskho";
import { useData } from "crustate/react";
import { CustomerData } from "data";

const SEARCH_SUGGESTION_SORT_WEIGHT = {
  product: 0,
  brand: 1,
  category: 2,
};

const sortSuggestions = (a, b) => {
  if (a.type !== b.type) {
    return SEARCH_SUGGESTION_SORT_WEIGHT[a.type] - SEARCH_SUGGESTION_SORT_WEIGHT[b.type];
  }

  return a.name.localeCompare(b.name);
};

const useSearchSuggestions = (searchQuery: string) => {
  const client = useClient();
  const [searchSuggestions, setSearchSuggestions] = useState<Array<SearchSuggestion>>([]);
  const { routes: { brandsView } } = useContext(StoreInfoContext);
  const customer = useData(CustomerData);
  const memberTargetList = customer.state === "LOGGED_IN" &&
  customer.data &&
  customer.data.memberTargetList &&
  customer.data.memberTargetList.list.length > 0 ?
    customer.data.memberTargetList.list :
    null;

  const transformSearchResult = (acc, curr): Array<SearchSuggestion> => {
    const result = [...acc];

    result.push({
      name: curr.name,
      urlKey: curr.url,
      product: {
        name: curr.name,
        url: curr.url,
        categories: curr.categories,
        attributes: curr.attributes,
      },
      type: "product",
    });

    if (curr.attributes.manufacturer) {
      const brand = curr.attributes.manufacturer;

      if (!acc.find(a => a.name === brand)) {
        result.push({
          name: curr.attributes.manufacturer,
          urlKey: `${brandsView.url}/${encodeURIComponent(brand)}`,
          type: "brand",
        });
      }
    }

    if (curr.categories.length > 0) {
      for (const category of curr.categories) {
        if (
          !result.find(a => a.name === category.name) &&
          (!memberTargetList ||
            memberTargetList.includes(category.awarditTargetId) ||
            !category.awarditTargetId)
        ) {
          result.push({
            name: category.name,
            urlKey: category.url,
            type: "category",
          });

          break;
        }
      }
    }

    return result;
  };

  const search = async query => {
    try {
      const result = await client(searchSuggestionsQuery, { query, pageSize: 2 });
      const items: Array<SearchSuggestion> = result.searchSuggestions.items
        .reduce(transformSearchResult, [])
        .sort(sortSuggestions);

      setSearchSuggestions(items);
    }
    catch {
      setSearchSuggestions([]);
    }
  };

  const searchDebounced = useRef(debounce(query => {
    if (!query || query.length < 3) {
      setSearchSuggestions([]);
      return;
    }

    search(query);
  }, 250)).current;

  useEffect(() => {
    searchDebounced(searchQuery);
  }, [searchQuery]);

  return searchSuggestions;
};

export default useSearchSuggestions;

