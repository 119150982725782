/* @flow */

import React, { useState, useContext, useEffect } from "react";
import cn from "classnames";
import { Helmet } from "react-helmet-async";
import { withRouter } from "react-router-dom";
import { useData } from "crustate/react";

import { AnalyticsContext } from "@crossroads/analytics";
import { QuoteData, CustomerData } from "data";
import Button from "components/Button";
import Container from "components/CheckoutView/Container";
import CartItem from "components/CheckoutView/Cart/CartItem";
import CustomerServiceLink from "components/CustomerServiceLink";
import ProductCarousel from "components/ProductCarousel";
import PartnerPointDeficitMessage from "./PartnerPointDeficitMessage";
import CartSummary from "components/CartSummary/Points";
import InsufficientFundsBanner from "components/InsufficientFundsBanner";
import { pointsOnlyProductInCart } from "helpers/points";
import { StoreInfoContext } from "entrypoint/shared";

import styles from "./styles.scss";

type PartnerSpecificPointDeficit = {
  partnerName: string,
  partnerPointDeficit: number,
};

type Props = {
  open: boolean,
  setOpen: boolean => void,
  canAfford: boolean,
  missingPoints: number,
  partnerSpecificPointDeficit: PartnerSpecificPointDeficit,
};

const Cart = ({ open, setOpen, canAfford, missingPoints, partnerSpecificPointDeficit }: Props) => {
  const gaContext = useContext(AnalyticsContext);
  const quoteData = useData(QuoteData);
  const customerData = useData(CustomerData);
  const { routes, content: { checkoutview,
    productCarousel: { useOnCart } } } = useContext(StoreInfoContext);
  const [showWarningMessage, setShowWarningMessage] = useState(false);

  const sendGAEvent = (product, quantity, type) => {
    gaContext.registerModifyCart({
      sku: product.sku,
      name: product.name,
      price: product.price,
      qty: quantity,
      attributes: {
        manufacturer: product.attributes.manufacturer,
      },
      categories: product.categories,
    }, type, product.price.exVat);
  };

  useEffect(() => {
    if (quoteData.data) {
      gaContext.viewedCart(quoteData.data.grandTotal.exVat, quoteData.data.items.map(item => {
        return {
          sku: item.product.sku,
          name: item.product.name,
          price: {
            exVat: item.rowTotal.exVat,
            incVat: item.rowTotal.incVat,
            vat: item.rowTotal.incVat - item.rowTotal.exVat,
          },
          qty: item.qty,
          attributes: {
            manufacturer: item.product.attributes.manufacturer,
          },
          categories: item.product.categories,
        };
      }));
    }
  }, []);

  useEffect(() => {
    if (!canAfford) {
      setShowWarningMessage(true);
    }
  }, [canAfford]);

  if (quoteData.state === "LOADING" || !quoteData.data || customerData.state !== "LOGGED_IN") {
    return null;
  }

  const { items } = quoteData.data;
  const processingItem = typeof quoteData.processingItem !== "undefined" ?
    quoteData.processingItem : null;
  const pointsOnlyProductMessage = pointsOnlyProductInCart(items);

  return (
    <>
      <Helmet title={checkoutview.pageTitleCart} />
      <Container
        right={
          <div>
            <CartSummary
              open={open}
              setOpen={setOpen}
              canAfford={canAfford}
              quoteData={quoteData}
              missingPoints={missingPoints}
            >
              <Button
                disabled={!canAfford || partnerSpecificPointDeficit.partnerPointDeficit > 0}
                variant="secondary"
                to={`${routes.checkoutView.url}/shipping`}
                className="awardit-buttonToShipping"
              >
                {checkoutview.toCheckout}
              </Button>
            </CartSummary>

            <CustomerServiceLink />
          </div>
        }
      >
        <div className={cn(styles.block, "awardit-checkoutViewCartItems")}>
          {showWarningMessage &&
            <InsufficientFundsBanner
              className={cn(styles.insufficientFunds,
                { [cn(styles.warning, "awardit-cantAfford")]: !canAfford }
              )}
              canAfford={canAfford}
              missingPoints={missingPoints}
              pointsOnlyProductMessage={pointsOnlyProductMessage}
            />
          }
          {partnerSpecificPointDeficit.partnerPointDeficit > 0 &&
            <PartnerPointDeficitMessage
              className={cn(styles.insufficientFunds, styles.warning, "awardit-cantAfford")}
              partnerSpecificPointDeficit={partnerSpecificPointDeficit} />
          }
          {items.map(x => {
            return (
              <CartItem
                key={x.product.name}
                item={x}
                quoteData={quoteData}
                processingItem={processingItem}
                sendGAEvent={sendGAEvent}
              />
            );
          })}
        </div>
      </Container>
      {(useOnCart) && (
        <ProductCarousel variant="POINTSBALANCE" />
      )}
    </>
  );
};

export default withRouter(Cart);
