/* @flow */

import React, { useState, useEffect, useCallback, useContext } from "react";
import { useTranslate } from "@awardit/react-use-translate";
import { useData, useSendMessage } from "crustate/react";
import { loadReportsHistory } from "state/reports";
import { ReportHistoryData, ReportListData } from "data";
import { StoreInfoContext } from "entrypoint/shared";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Breadcrumbs from "components/Breadcrumbs";
import Wrapper from "components/Wrapper";
import { Dropdown, DropdownItem } from "@crossroads/ui-components";
import HistoryTableLarge, { HistoryTableLargeHint } from "components/ReportsView/ReportsHistoryView/HistoryTableLarge";
import HistoryTableSmall, { TableSmallHint } from "components/ReportsView/ReportsHistoryView/HistoryTableSmall";
import cn from "classnames";

import styles from "./styles.scss";

const ReportsHistoryView = () => {
  const t = useTranslate();
  const reportHistory = useData(ReportHistoryData);
  const reportlistData = useData(ReportListData);
  const { routes, content: { reportsview } } = useContext(StoreInfoContext);
  const [current, setCurrent] = useState<string | null>(null);
  const [currentDistrict, setCurrentDistrict] = useState("");
  const sendMessage = useSendMessage();

  const load = useCallback(() => {
    sendMessage(loadReportsHistory({ id: currentDistrict }));
  }, [sendMessage, loadReportsHistory, reportHistory, currentDistrict]);

  useEffect(() => {
    load();
  }, [currentDistrict]);

  return (

    <Wrapper variant="pageWrapper" className={cn("awardit-pageWrapper", styles.wrapper)}>
      <Helmet
        title={reportsview.reportHistoryTitle}
      />

      <Breadcrumbs current={reportsview.reportHistoryTitle} />

      <div className={styles.intro}>
        <h1>{reportsview.reportHistoryTitle}</h1>
        <p>{reportsview.reportHistoryDescription}</p>
        <Link className={styles.link} to={routes.reportsView.url}>{t("REPORTS.BACK")}</Link>
      </div>

      <h2>{reportsview.reportHistorySubTitle}</h2>

      {reportlistData.state === "LOADED" &&
      reportlistData.data.length > 0 &&
        <Dropdown
          className={styles.districtSelector}
          name="productName"
          variant="primary"
          value={currentDistrict !== "" ? currentDistrict : reportlistData.data[0].id.toString()}
          onChange={(value: any) => {
            setCurrentDistrict(value);
          }}
        >
          {reportlistData.data.map(item => (
            <DropdownItem
              key={`from_${item.id}`}
              className={styles.inputWrapper}
              value={`${item.id}`}
            >
              {`${item.name}`}
            </DropdownItem>
          ))}
        </Dropdown>
      }

      {reportHistory.state === "LOADED" &&
      reportHistory.data.header &&
      reportHistory.data.table ?
        <HistoryTableLarge
          headers={reportHistory.data.header}
          table={reportHistory.data.table}
        /> :
        <HistoryTableLargeHint />
      }
      {reportHistory.state === "LOADED" &&
      reportHistory.data.header &&
      reportHistory.data.table ?
        <HistoryTableSmall
          headers={reportHistory.data.header}
          table={reportHistory.data.table}
          current={current}
          onClick={x => setCurrent(current !== x ? x : null)}
        /> :
        <TableSmallHint />
      }
    </Wrapper>
  );
};

export default ReportsHistoryView;
