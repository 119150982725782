/* @flow */

import React, { useContext } from "react";
import cn from "classnames";
import { Helmet } from "react-helmet-async";
import { StoreInfoContext } from "entrypoint/shared";
import LoginForm from "components/LoginForm";
import Button from "components/Button";
import { useErrorParams } from "helpers/use-error-params";

import styles from "./styles.scss";

const LoginView = () => {
  const { content: { loginview } } = useContext(StoreInfoContext);
  useErrorParams({ "bad_loginkey": "errorInvalidLogin" });

  return (
    <div className={cn(styles.block, "awardit-loginView")}>
      <Helmet title={loginview.pageTitle} />
      <div className={styles.content}>

        <div className={cn("awardit-loginViewContainer", styles.container)}>
          {loginview.description &&
            <div className={styles.container__text}>
              <h1 className="awardit-loginViewHeader">{loginview.heading}</h1>
              {/* eslint-disable react/no-danger */}
              <p dangerouslySetInnerHTML={{ __html: loginview.description }} />
              {/* eslint-enable react/no-danger */}
              {loginview.createAccountHeading &&
                <h2 className={styles.title}>{loginview.createAccountHeading}</h2>
              }
              {loginview.createAccountButtonUrl &&
                <a href={loginview.createAccountButtonUrl} rel="noopener noreferrer">
                  <Button variant="primary" size="medium">
                    {loginview.createAccountButtonText}
                  </Button>
                </a>
              }
            </div>
          }
          <LoginForm className={styles.login_form} />
        </div>
      </div>
    </div>
  );
};

export default LoginView;
